import React from 'react';
import { Form, Row, Col, Collapse, DatePicker, Select } from 'antd';
import { GENERAL, MASTERS, SANITARIA } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha_evento`,
  VACUNACION_VACUNA: 'vacuna',
  SINDROME: 'sindrome',
  RESPONSABLE: 'responsable',
  BAJA: 'baja',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha vacunación',
  [PARAMS.VACUNACION_VACUNA]: 'Vacuna',
  [PARAMS.SINDROME]: 'Síndrome',
  [PARAMS.RESPONSABLE]: 'Responsable',
  [PARAMS.BAJA]: 'Estado de unidad',
};

const FormFields = ({ api }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [
          MASTERS.SANITARIA_TYPES.SINDROME,
          MASTERS.PRODUCTIVA_TYPES.RESPONSABLE,
        ],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  const { data: vacunas, isLoading: isLoadingVacunas } = useQuery(
    ['Sanitaria::Vacunas', { inputType: SANITARIA.VACUNA }],
    api.fetchInsumo,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Vacunación" key="vacunacion">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={GENERAL.DATE_FORMAT.FRONT}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.VACUNACION_VACUNA]}
                  name={PARAMS.VACUNACION_VACUNA}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingVacunas}
                    options={MASTERS.generateOptions(vacunas?.results)}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.SINDROME]} name={PARAMS.SINDROME}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.SANITARIA_TYPES.SINDROME]}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.RESPONSABLE]}
                  name={PARAMS.RESPONSABLE}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.RESPONSABLE]}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label={tags[PARAMS.BAJA]} name={PARAMS.BAJA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    options={GENERAL.optionsBoolToStr(MASTERS.BAJA_OPTIONS)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
