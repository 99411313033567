import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Spin, Button } from 'antd';
import { EditOutlined, KeyOutlined, DeleteOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { DeleteModal, RollbackButton } from 'modules/core/components';
import { EstablishmentDrawerAdd } from 'modules/admin/components';
import {
  EstablishmentInfoCard,
  EstablishmentLicenseCard,
  LicenseModalAdd,
  UserCarousel,
  UserModalAdd,
  UserModalResetPw,
} from './components';
import './FincaClientDetail.scss';

const FincaClientDetail = ({ api }) => {
  let history = useHistory();
  const { est: estId } = useParams();
  const {
    data: estInfo,
    isLoading: isLoadingEst,
    refetch: rEst,
  } = useQuery(['Establishment::Info', { estId }], api.fetchEstablishment);

  const { mutate: deleteLic, isLoading: isDeletingLic } = useMutation(
    api.deleteLicense
  );
  const { mutate: deleteUser, isLoading: isDeletingUser } = useMutation(
    api.deleteUser
  );

  const [editingEst, setEditingEst] = useState(false);
  const [addingLicense, setAddingLicense] = useState(false);
  const [deletingLicense, setDeletingLicense] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [resettingUser, setResettingUser] = useState(null);
  const [editingUser, setEditingUser] = useState(null);

  const handleDeleteLic = ({ onSuccess, onError }) => {
    deleteLic({ licenseId: estInfo.licencia.id }, { onSuccess });
  };

  const handleDeleteUser = ({ onSuccess, onError }) => {
    deleteUser({ userId: deletingUser.id }, { onSuccess });
  };

  if (isLoadingEst) return <Spin className="finca-client-loading" />;

  const renderDeleteLicense = () => (
    <DeleteModal
      title="Eliminar licencia"
      isDeleting={isDeletingLic}
      visible={deletingLicense}
      onCancel={() => setDeletingLicense(false)}
      onConfirm={handleDeleteLic}
      onSuccess={() => {
        setDeletingLicense(false);
        rEst();
      }}
      onSuccessMessage="La licencia se eliminó con éxito."
    >
      <div>
        Estás eliminando la licencia del establecimiento{' '}
        <b>{estInfo?.establecimiento?.name}</b>.
        <br />
        ¿Confirmás que esta licencia ya no está vigente?
      </div>
    </DeleteModal>
  );

  const renderDeleteUser = () => (
    <DeleteModal
      title="Eliminar usuario"
      isDeleting={isDeletingUser}
      visible={!!deletingUser}
      onCancel={() => setDeletingUser(false)}
      onConfirm={handleDeleteUser}
      onSuccess={() => {
        setDeletingUser(null);
        rEst();
      }}
      onSuccessMessage={`El usuario ${deletingUser?.first_name} se eliminó con éxito.`}
    >
      <div>
        Estás eliminando al usuario <b>{deletingUser?.first_name}</b> del
        establecimiento {estInfo?.establecimiento?.name}.<br />
        ¿Confirmás que este usuario ya no estará disponible?
      </div>
    </DeleteModal>
  );

  return (
    <Row className="finca-client-container">
      <Col span={20}>
        <RollbackButton onRollback={() => history.push('/admin')} />
      </Col>
      <Col span={20} offset={2} className="est-title">
        {estInfo.establecimiento.name}
      </Col>
      <Col span={20} offset={2} className="est-data">
        <Row>
          <Col span={15} className="est-detail">
            <div className="est-container-title">Detalle</div>
            <EstablishmentInfoCard
              data={estInfo.establecimiento}
              onEdit={() => setEditingEst(true)}
            />
            <EstablishmentDrawerAdd
              open={editingEst}
              isEdit
              editEst={estInfo.establecimiento}
              success={() => {
                setEditingEst(false);
                rEst();
              }}
              close={() => setEditingEst(false)}
            />
          </Col>
          <Col span={8} offset={1} className="est-license">
            <div className="est-container-title">
              Licencia
              <Button
                size={'small'}
                type="primary"
                onClick={() => setAddingLicense(true)}
              >
                NUEVA LICENCIA
              </Button>
            </div>
            <EstablishmentLicenseCard
              data={estInfo.licencia}
              onDelete={() => setDeletingLicense(true)}
            />
            <LicenseModalAdd
              visible={addingLicense}
              est={estInfo.establecimiento}
              success={() => {
                setAddingLicense(false);
                rEst();
              }}
              close={() => setAddingLicense(false)}
            />
            {renderDeleteLicense()}
          </Col>
        </Row>
      </Col>
      <Col span={20} offset={2} className="est-users">
        <div className="est-container-title">Usuarios</div>
        <UserCarousel
          data={estInfo.usuarios}
          actions={(user) => [
            {
              title: 'Modificar',
              icon: <EditOutlined />,
              onClick: () => setEditingUser(user),
            },
            {
              title: 'Reseteo de contraseña',
              icon: <KeyOutlined />,
              onClick: () => setResettingUser(user),
            },
            {
              title: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => setDeletingUser(user),
            },
          ]}
        />
        <Button
          type="link"
          className="est-users-add"
          onClick={() => setAddingUser(true)}
        >
          Agregar usuario
        </Button>
        <UserModalAdd
          user={editingUser}
          visible={addingUser || !!editingUser}
          est={estInfo.establecimiento}
          success={() => {
            setAddingUser(false);
            setEditingUser(null);
            rEst();
          }}
          close={() => {
            setAddingUser(false);
            setEditingUser(null);
          }}
        />
        <UserModalResetPw
          visible={!!resettingUser}
          user={resettingUser}
          success={() => setResettingUser(null)}
          close={() => setResettingUser(null)}
        />
        {renderDeleteUser()}
      </Col>
    </Row>
  );
};

export default withAPI(FincaClientDetail);
