import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Form, Input, DatePicker, message as antMessage } from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { ECONOMICA, GENERAL, MANAGEMENT } from 'modules/core/constants';
import FilterSider from 'modules/core/components/Filter';
import { EventsHeader } from 'modules/management/components';
import CompraInsumoTable from './CompraInsumoTable/CompraInsumoTable';

const { Item: FormItem } = Form;

const CompraInsumosEvent = ({ api }) => {
  const { eventType } = useParams();
  const compraInsumoEventTitle =
    ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_FORMATTED[eventType];
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const { data, isLoading, refetch } = useQuery(
    ['CompraInsumos::fetchCompraInsumosAdquisiciones', { eventType, params }],
    api.fetchCompraInsumosAdquisiciones
  );

  const { mutate: compraInsumo, isLoading: registering } = useMutation(
    api.addEconomicaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    type: 'radio',
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };

  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const egresoCompraInsumos = {
          fecha: GENERAL.dateToBack(values.date),
          monto: values.monto,
          adquisicion: selectedRows.keys[0],
        };
        const onSuccess = () => {
          antMessage.success('El evento se registro con éxito.');
          history.push(location.pathname);
        };
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        compraInsumo(
          { eventType, body: egresoCompraInsumos },
          { onSuccess, onError }
        );
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title={compraInsumoEventTitle}
        description={
          !actionInProgress
            ? 'Adquisiciones disponibles para realizar compra'
            : `${selectedRows.rows.length} adquisiciones seleccionadas`
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.ECONOMICA,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
          withoutExportFile: true,
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => {
    return (
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <FilterSider onChange={filters.set} />
          </Col>
          <Col span={24}>
            <CompraInsumoTable
              data={data?.results}
              eventType={eventType}
              rowSelection={rowSelection}
              loading={isLoading}
              showTotal={true}
              pagination={{ ...pagination.get(), total: data?.count }}
              onChange={(p, f, s) => {
                pagination.set(p);
                sorter.set(s);
              }}
            />
          </Col>
        </Row>
      </Col>
    );
  };

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Monto"
              name="monto"
              rules={[
                {
                  required: true,
                  type: 'string',
                  pattern: /^[0-9?]*\.?[0-9]*$/,
                  message: 'Ingrese monto',
                },
              ]}
            >
              <Input placeholder="Ingrese monto" />
            </FormItem>
          </Col>
          <Col span={24}>
            <CompraInsumoTable
              loading={isLoading}
              data={selectedRows.rows}
              eventType={eventType}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(CompraInsumosEvent);
