import {
  MASTERS,
  PRODUCTIVA,
  SANITARIA,
  NUTRICIONAL,
} from 'modules/core/constants';

const GeneralAPI = (API) => {
  // ++ GENERICA ++
  API.fetchMasters = function ({ queryKey }) {
    const [key, { masterType, id = '', params }] = queryKey;
    const getFn = (masterType) => {
      if (MASTERS.PRODUCTIVA_TYPES_LIST.includes(masterType))
        return API.fetchProductivaMasters;
      if (MASTERS.SANITARIA_TYPES_LIST.includes(masterType))
        return API.fetchSanitariaMasters;
      if (MASTERS.NUTRICIONAL_TYPES_LIST.includes(masterType))
        return API.fetchNutricionalMasters;
      if (MASTERS.ECONOMICA_TYPES_LIST.includes(masterType))
        return API.fetchEconomicaMasters;
    };
    return getFn(masterType)({ queryKey: [key, { masterType, id, params }] });
  };

  API.fetchMastersFormOptions = function ({ queryKey }) {
    const [key, { masterTypes, params, paramsByType = {} }] = queryKey;
    const getFn = (masterType) => {
      if (MASTERS.PRODUCTIVA_TYPES_LIST.includes(masterType))
        return API.fetchProductivaMasters;
      if (MASTERS.SANITARIA_TYPES_LIST.includes(masterType))
        return API.fetchSanitariaMasters;
      if (MASTERS.NUTRICIONAL_TYPES_LIST.includes(masterType))
        return API.fetchNutricionalMasters;
      if (MASTERS.ECONOMICA_TYPES_LIST.includes(masterType))
        return API.fetchEconomicaMasters;
    };

    return Promise.all(
      masterTypes.map((masterType) =>
        getFn(masterType)({
          queryKey: [
            key,
            {
              masterType,
              params: {
                ...params,
                ...paramsByType?.[masterType],
              },
            },
          ],
        })
      )
    ).then((rs) =>
      masterTypes.reduce(
        (options, m, i) => ({
          ...options,
          [m]: MASTERS.generateOptions(rs[i]?.results),
        }),
        {}
      )
    );
  };

  API.addMaster = function ({ body, masterType }) {
    const getFn = (masterType) => {
      if (MASTERS.PRODUCTIVA_TYPES_LIST.includes(masterType))
        return API.addProductivaMaster;
      if (MASTERS.SANITARIA_TYPES_LIST.includes(masterType))
        return API.addSanitariaMaster;
      if (MASTERS.ECONOMICA_TYPES_LIST.includes(masterType))
        return API.addNutricionalMaster;
    };
    return getFn(masterType)({ body, masterType });
  };

  API.editMaster = function ({ id, body, masterType }) {
    const getFn = (masterType) => {
      if (MASTERS.PRODUCTIVA_TYPES_LIST.includes(masterType))
        return API.editProductivaMaster;
      if (MASTERS.SANITARIA_TYPES_LIST.includes(masterType))
        return API.editSanitariaMaster;
      if (MASTERS.ECONOMICA_TYPES_LIST.includes(masterType))
        return API.editNutricionalMaster;
    };
    return getFn(masterType)({ id, body, masterType });
  };

  API.deleteMaster = function ({ id, masterType }) {
    const getFn = (masterType) => {
      if (MASTERS.PRODUCTIVA_TYPES_LIST.includes(masterType))
        return API.deleteProductivaMaster;
      if (MASTERS.SANITARIA_TYPES_LIST.includes(masterType))
        return API.deleteSanitariaMaster;
      if (MASTERS.ECONOMICA_TYPES_LIST.includes(masterType))
        return API.deleteNutricionalMaster;
    };
    return getFn(masterType)({ id, masterType });
  };

  // ++ INSUMOS ++
  API.fetchInsumo = function ({ queryKey }) {
    const [key, { inputType, params }] = queryKey;
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.fetchProductivaInsumo;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.fetchSanitariaInsumo;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.fetchNutricionalInsumo;
    };
    return getFn(inputType)({ queryKey: [key, { inputType, params }] });
  };

  API.addInsumo = function ({ body, inputType }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.addProductivaInsumo;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.addSanitariaInsumo;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.addNutricionalInsumo;
    };
    return getFn(inputType)({ body, inputType });
  };

  API.editInsumo = function ({ id, body, inputType }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.editProductivaInsumo;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.editSanitariaInsumo;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.editNutricionalInsumo;
    };
    return getFn(inputType)({ id, body, inputType });
  };

  API.deleteInsumo = function ({ id, inputType }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.deleteProductivaInsumo;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.deleteSanitariaInsumo;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.deleteNutricionalInsumo;
    };
    return getFn(inputType)({ id, inputType });
  };

  API.decrementInsumo = function ({ id, inputType, amount }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.decrementProductivaInsumo;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.decrementSanitariaInsumo;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.decrementNutricionalInsumo;
    };
    return getFn(inputType)({ id, inputType, amount });
  };

  // ++ ADQUISICIONES ++
  API.fetchAdquisicion = function ({ queryKey }) {
    const [key, { inputType, params }] = queryKey;
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.fetchProductivaAdquisicion;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.fetchSanitariaAdquisicion;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.fetchNutricionalAdquisicion;
    };
    return getFn(inputType)({ queryKey: [key, { inputType, params }] });
  };

  API.addAdquisicion = function ({ body, inputType }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.addProductivaAdquisicion;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.addSanitariaAdquisicion;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.addNutricionalAdquisicion;
    };
    return getFn(inputType)({ body, inputType });
  };

  API.editAdquisicion = function ({ id, body, inputType }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.editProductivaAdquisicion;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.editSanitariaAdquisicion;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.editNutricionalAdquisicion;
    };
    return getFn(inputType)({ id, body, inputType });
  };

  API.deleteAdquisicion = function ({ id, inputType }) {
    const getFn = (inputType) => {
      if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.deleteProductivaAdquisicion;
      if (SANITARIA.NOEVENT_TYPES_LIST.includes(inputType))
        return API.deleteSanitariaAdquisicion;
      if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(inputType))
        return API.deleteNutricionalAdquisicion;
    };
    return getFn(inputType)({ id, inputType });
  };

  API.undoRecord = function ({ eventType, eventId }) {
    const getFn = (eventType) => {
      if (PRODUCTIVA.EVENT_TYPES.includes(eventType))
        return API.undoProductivaEvent;
      if (SANITARIA.EVENT_TYPES.includes(eventType))
        return API.deleteSanitariaEvent;
      if (NUTRICIONAL.EVENT_TYPES.includes(eventType))
        return API.deleteNutricionalEvent;
    };
    return getFn(eventType)({ eventType, eventId });
  };
  // ++ FIN GENERICAS ++

  // ++ MOBILE ++
  API.mobileEventSync = function ({ queryKey }) {
    const [, { eventType, params }] = queryKey;
    const qp = API.makeQueryString(params);

    return fetch(
      `/api/core/eventos/${eventType}/mobile-sync-upload-history/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.mobileEventSyncMarkDownloaded = function ({ fileId }) {
    return fetch(`/api/core/eventos/batchload/${fileId}/set-downloaded/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.mobileConfirmSyncFile = function ({ fileId }) {
    return fetch(`/api/core/eventos/batchload/${fileId}/mobile-sync-confirm/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleBlobOnerror);
  };

  API.mobileUploadSyncFile = function ({ fileId, inputFile }) {
    return fetch(
      `/api/core/eventos/batchload/${fileId}/mobile-sync-upload-file/`,
      {
        method: 'POST',
        headers: API.getAuthFormHeaders(),
        body: inputFile,
      }
    ).then(API.handleBlobOnerror);
  };

  API.mobileDeleteSyncFile = function ({ fileId }) {
    return fetch(`/api/core/eventos/batchload/${fileId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchSyncNotifications = function () {
    return fetch(`/api/core/eventos/batchload/get-pendientes/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.mobileFetchUnitsImageSync = function () {
    return fetch(`/api/core/unidades/imagenes/imagen-upload-history/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.mobileDeleteUnitsImageSync = function ({ fileId }) {
    return fetch(`/api/core/unidades/imagenes/${fileId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.mobileUnitsSyncProcessImage = function ({ fileId }) {
    return fetch(`/api/core/unidades/imagenes/${fileId}/imagen-process/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.mobileUnitsSyncProcessXls = function ({ fileId, inputFile = null }) {
    return fetch(`/api/core/unidades/imagenes/${fileId}/excel-confirm/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      ...(inputFile && { body: inputFile }),
    }).then(API.handleBlobOnerror);
  };
  // ++ FIN MOBILE ++

  API.fetchTareas = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/tareas/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // NOTE: At the moment not in use, delete after May24 release
  API.fetchTop3Tareas = function ({ queryKey }) {
    return fetch(`/api/core/tareas/top-pending/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.setTareaRealizada = function ({ taskId, data }) {
    return fetch(`/api/core/tareas/${taskId}/set-realizado/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(data),
    }).then(API.handleResponse);
  };

  API.addTarea = function (task) {
    return fetch(`/api/core/tareas/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(task),
    }).then(API.handleResponse);
  };

  API.editTarea = function ({ taskId, task }) {
    return fetch(`/api/core/tareas/${taskId}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(task),
    }).then(API.handleResponse);
  };

  API.deleteTarea = function (taskId) {
    return fetch(`/api/core/tareas/${taskId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  return API;
};

export default GeneralAPI;
