import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { ContainerOutlined } from '@ant-design/icons';
import {
  Modal,
  message as antMessage,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
} from 'antd';
import { withAPI } from 'modules/api';
import { ModalTitle } from 'modules/core/components';
import { GENERAL } from 'modules/core/constants';

const FinishDietaForm = ({ requiredEndWeight = false }) => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label="Fecha de finalización"
          name="endDate"
          rules={[{ required: true, message: 'Ingrese fecha' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={GENERAL.DATE_FORMAT.FRONT}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Peso final"
          name="endWeight"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: requiredEndWeight,
              type: 'string',
              pattern: /^[0-9?]*\.?[0-9]*$/,
              message: 'Ingrese el peso',
            },
          ]}
        >
          <Input placeholder="Ingrese peso en kgs" />
        </Form.Item>
      </Col>
    </Row>
  );
};

const FinishDietaModal = ({ api, visible, dieta = null, success, close }) => {
  const { mutate: finish, isLoading: isFinishing } = useMutation(
    api.finishDieta
  );
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [visible, form]);

  const finishDieta = () => {
    form
      .validateFields()
      .then((values) => {
        const newDieta = {
          fecha_fin: GENERAL.dateToBack(values.endDate),
          peso_fin: values.endWeight,
        };
        const onSuccess = () => {
          success();
          antMessage.success('Dieta finalizada con éxito.');
          close();
        };
        const onError = ({ description } = {}) => {
          antMessage.error(description || 'Error');
        };
        finish({ id: dieta.id, dieta: newDieta }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        <ModalTitle
          icon={<ContainerOutlined />}
          title={`Finalizar dieta ${dieta.unidad}`}
        />
      }
      forceRender
      open={visible}
      okText="FINALIZAR"
      cancelText="CANCELAR"
      onOk={finishDieta}
      onCancel={close}
      confirmLoading={isFinishing}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <FinishDietaForm requiredEndWeight={!!dieta.peso_inicio} />
      </Form>
    </Modal>
  );
};

export default withAPI(FinishDietaModal);
