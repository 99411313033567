import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, List, Row, Divider } from 'antd';
import { withAPI } from 'modules/api';
import { GENERAL } from 'modules/core/constants';
import {
  ScreenHeader,
  Card,
  DeleteModal,
  Title,
} from 'modules/core/components';
import ManagementConfigModal from './ManagementConfigModal';
import _ from 'lodash';

const ManagementConfig = ({
  api,
  masterType,
  masterName,
  groupBy = null,
  renderForm = () => {},
}) => {
  const [addEditModalVisible, setAddEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const { data, isLoading, refetch } = useQuery(
    [`Gestion::fetchMasters${masterType}`, { masterType }],
    api.fetchMasters
  );

  const { mutate: deleteSanitariaMaster, isLoading: isDeleting } = useMutation(
    api.deleteMaster
  );

  const actionsHandler = ({ item, action }) => {
    setSelectedItem(item);
    if (action === GENERAL.ACTIONS.MODIFICAR) {
      setAddEditModalVisible(true);
    }
    if (action === GENERAL.ACTIONS.ELIMINAR) {
      setDeleteModalVisible(true);
    }
  };

  const handleDelete = ({ onSuccess, onError }) => {
    deleteSanitariaMaster(
      { id: selectedItem.id, masterType },
      { onSuccess, onError }
    );
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedItem({});
  };

  const renderItemList = (data) => {
    return (
      <List
        loading={isLoading}
        dataSource={data}
        grid={{ gutter: 24, column: 4, xs: 1, sm: 2, md: 3 }}
        renderItem={(item) => (
          <List.Item>
            <Card
              title={item.nombre}
              description={item?.descripcion}
              moreActions={[
                {
                  title: 'Modificar',
                  icon: <EditOutlined />,
                  onClick: () =>
                    actionsHandler({
                      item,
                      action: GENERAL.ACTIONS.MODIFICAR,
                    }),
                },
                {
                  title: 'Eliminar',
                  icon: <DeleteOutlined />,
                  onClick: () =>
                    actionsHandler({
                      item,
                      action: GENERAL.ACTIONS.ELIMINAR,
                    }),
                },
              ]}
            />
          </List.Item>
        )}
      />
    );
  };

  const renderGroupByItemList = (data) => {
    return _.map(data, (items, key) => (
      <div key={key} style={{ marginBottom: 8 }}>
        <Title
          level={3}
          text={key.toUpperCase()}
          style={{ marginBottom: 16 }}
        />
        {renderItemList(items)}
        <Divider />
      </div>
    ));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader
          title={masterName.PLURAL}
          rightActions={
            <Button type="primary" onClick={() => setAddEditModalVisible(true)}>
              AGREGAR
            </Button>
          }
        />
      </Col>
      <Col span={24}>
        {!groupBy && renderItemList(data?.results)}
        {groupBy &&
          renderGroupByItemList(
            _.groupBy(data?.results, (item) => _.get(item, groupBy, 'N/A'))
          )}
      </Col>
      <ManagementConfigModal
        visible={addEditModalVisible}
        initialValues={selectedItem}
        masterType={masterType}
        masterName={masterName}
        onSuccess={refetch}
        onCancel={() => {
          setAddEditModalVisible(false);
          setSelectedItem({});
        }}
        renderForm={renderForm}
      />
      <DeleteModal
        title={`Eliminar ${masterName.LOWER}`}
        bodyText={<b>{selectedItem?.nombre}</b>}
        isDeleting={isDeleting}
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        onSuccess={() => {
          refetch();
          handleCancelDelete();
        }}
        onSuccessMessage={`${masterName.CAPITALIZE} eliminado con éxito.`}
      />
    </Row>
  );
};

export default withAPI(ManagementConfig);
