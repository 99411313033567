import React from 'react';
import { Form, Row, Col, Collapse, DatePicker, Select } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha`,
  UBICACION_FROM: 'inicial_ubicacion',
  UBICACION_TO: 'final_ubicacion',
  RODEO_FROM: 'inicial_rodeo',
  RODEO_TO: 'final_rodeo',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha movimiento',
  [PARAMS.UBICACION_FROM]: 'Ubicación inicial',
  [PARAMS.UBICACION_TO]: 'Ubicación final',
  [PARAMS.RODEO_FROM]: 'Rodeo inicial',
  [PARAMS.RODEO_TO]: 'Rodeo final',
};

const FormFields = ({ api }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.UBICACION,
          MASTERS.PRODUCTIVA_TYPES.RODEO,
        ],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Movimiento" key="movimiento">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={GENERAL.DATE_FORMAT.FRONT}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label={tags[PARAMS.UBICACION_FROM]}
                  name={PARAMS.UBICACION_FROM}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.UBICACION]}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label={tags[PARAMS.UBICACION_TO]}
                  name={PARAMS.UBICACION_TO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.UBICACION]}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item label={tags[PARAMS.RODEO_FROM]} name={PARAMS.RODEO_FROM}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.RODEO]}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item label={tags[PARAMS.RODEO_TO]} name={PARAMS.RODEO_TO}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.RODEO]}
                  />
                </Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
