import React from 'react';
import { Form, Row, Col, Collapse, DatePicker, Select } from 'antd';
import { GENERAL, MASTERS, NUTRICIONAL } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha_evento`,
  NUTRICIONAL_PASTO: 'disponibilidad_pasto',
  NUTRICIONAL_SUPLEMENTO: 'suplemento',
  NUTRICIONAL_ESTADO: 'estado',
  BAJA: 'baja',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha dieta',
  [PARAMS.NUTRICIONAL_PASTO]: 'Disponibilidad de pasto',
  [PARAMS.NUTRICIONAL_SUPLEMENTO]: 'Suplemento',
  [PARAMS.NUTRICIONAL_ESTADO]: 'Estado',
  [PARAMS.BAJA]: 'Estado de unidad',
};

const FormFields = ({ api }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'Masters::SuplementoPasto',
      {
        masterTypes: [MASTERS.NUTRICIONAL_TYPES.DISPONIBILIDAD_PASTO],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  const { data: suplementos, isLoading: isLoadingSuplementos } = useQuery(
    ['Nutricional::Suplementos', { inputType: NUTRICIONAL.SUPLEMENTO }],
    api.fetchInsumo,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Dieta" key="dieta">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={GENERAL.DATE_FORMAT.FRONT}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.NUTRICIONAL_PASTO]}
                  name={PARAMS.NUTRICIONAL_PASTO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={
                      options?.[MASTERS.NUTRICIONAL_TYPES.DISPONIBILIDAD_PASTO]
                    }
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.NUTRICIONAL_SUPLEMENTO]}
                  name={PARAMS.NUTRICIONAL_SUPLEMENTO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingSuplementos}
                    options={MASTERS.generateOptions(suplementos?.results)}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.NUTRICIONAL_ESTADO]}
                  name={PARAMS.NUTRICIONAL_ESTADO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    options={[
                      { value: 'Pendiente', label: 'Pendiente' },
                      { value: 'Finalizado', label: 'Finalizada' },
                    ]}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label={tags[PARAMS.BAJA]} name={PARAMS.BAJA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    options={GENERAL.optionsBoolToStr(MASTERS.BAJA_OPTIONS)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
