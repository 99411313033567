import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { Row, Col } from 'antd';
import { CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { DropdownMenu, DeleteModal } from 'modules/core/components';
import FilterSider from 'modules/core/components/Filter';
import {
  InsumosHeader,
  InsumoModal,
  InsumosModalDecrement,
} from 'modules/management/components';
import { InsumosCards } from '../components/';
import EmbrionList from './EmbrionList/EmbrionList';
import EmbrionForm from './EmbrionForm';
import { GENERAL, PRODUCTIVA, UNITS } from 'modules/core/constants';

const Embrion = ({ api }) => {
  const inputType = PRODUCTIVA.EMBRION;
  const masterName = {
    CAPITALIZE: 'Embriones',
    LOWER: 'embriones',
  };
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [addInsumo, setAddInsumo] = useState(false);
  const [decrementEmbrion, setDecrementEmbrion] = useState(null);
  const [deleteEmbrion, setDeleteEmbrion] = useState(null);

  const { data: cards, refetch: rKpis } = useQuery(
    ['Insumos::fetchKPIEmbriones', { inputType, params: filters.get() }],
    api.fetchKPIProductivaInsumo
  );

  const {
    data: embriones,
    isLoading: fetchingEmbriones,
    refetch: rEmbriones,
  } = useQuery(
    ['Insumos::fetchEmbrion', { inputType, params }],
    api.fetchInsumo
  );

  const { data: females, isLoading: isLoadingFemales } = useQuery(
    [
      'Units::females',
      { params: { sexo: UNITS.HEMBRAS, bajas: true, madres: true } },
    ],
    api.fetchUnits,
    { enabled: addInsumo }
  );
  const femalesOptions = GENERAL.toRPApodoOptions(females?.results);

  const { data: males, isLoading: isLoadingMales } = useQuery(
    [
      'Units::males',
      { params: { sexo: UNITS.MACHOS, bajas: true, padres: true } },
    ],
    api.fetchUnits,
    { enabled: addInsumo }
  );
  const malesOptions = GENERAL.toRPApodoOptions(males?.results);

  const { mutate: deleteInsumo, isLoading: isDeleting } = useMutation(
    api.deleteInsumo
  );

  const refetch = () => {
    rKpis();
    rEmbriones();
  };

  const handleDelete = ({ onSuccess, onError }) => {
    deleteInsumo({ id: deleteEmbrion, inputType }, { onSuccess, onError });
  };

  const renderActions = ({ id }) => (
    <DropdownMenu
      menu={[
        {
          title: 'Decrementar',
          icon: <CaretDownOutlined />,
          onClick: () => setDecrementEmbrion(id),
        },
        {
          title: 'Eliminar',
          icon: <DeleteOutlined />,
          onClick: () => setDeleteEmbrion(id),
        },
      ]}
    />
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <InsumosHeader title="Embriones" onClick={() => setAddInsumo(true)} />
      </Col>
      <Col span={4}>
        <InsumosCards data={cards} />
      </Col>
      <Col span={20}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <FilterSider onChange={filters.set} />
          </Col>
          <Col span={24}>
            <EmbrionList
              data={embriones}
              actions={renderActions}
              loading={fetchingEmbriones}
              showTotal={true}
              pagination={{ ...pagination.get(), total: embriones?.count }}
              onChange={(p, f, s) => {
                pagination.set(p);
                sorter.set(s);
              }}
            />
          </Col>
          <InsumoModal
            visible={addInsumo}
            inputType={inputType}
            masterName={masterName}
            onSuccess={refetch}
            onCancel={() => setAddInsumo(false)}
            renderForm={() => (
              <EmbrionForm
                males={{
                  options: malesOptions,
                  isLoading: isLoadingMales,
                }}
                females={{
                  options: femalesOptions,
                  isLoading: isLoadingFemales,
                }}
              />
            )}
          />
          <InsumosModalDecrement
            id={decrementEmbrion}
            inputType={inputType}
            visible={!!decrementEmbrion}
            onSuccess={() => {
              setDecrementEmbrion(null);
              refetch();
            }}
            onCancel={() => setDecrementEmbrion(null)}
          />
          <DeleteModal
            title="Eliminar insumo"
            bodyText={<span>este insumo</span>}
            isDeleting={isDeleting}
            visible={!!deleteEmbrion}
            onCancel={() => setDeleteEmbrion(null)}
            onConfirm={handleDelete}
            onSuccess={() => {
              setDeleteEmbrion(null);
              refetch();
            }}
            onSuccessMessage={'Insumo eliminado con éxito.'}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default withAPI(Embrion);
