import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { withAPI } from 'modules/api';
import { Button, Row, Col } from 'antd';
import { EyeOutlined, UndoOutlined } from '@ant-design/icons';
import {
  ScreenHeader,
  Table,
  DropdownMenu,
  DeleteModal,
} from 'modules/core/components';
import FilterSider from 'modules/core/components/Filter';
import AdquisicionesModalAdd from './AdquisicionesModalAdd/AdquisicionesModalAdd';
import AdquisicionesModalEdit from './AdquisicionesModalEdit/AdquisicionesModalEdit';

const InsumosRecordHistory = ({ api, state, columns, customAddForm }) => {
  let history = useHistory();
  const [addAdquisicion, setAddAdquisicion] = useState(false);
  const { mutate: deleteAdquisicion, isLoading: isDeleting } = useMutation(
    api.deleteAdquisicion
  );

  const handleDelete = ({ onSuccess, onError }) => {
    deleteAdquisicion(
      { id: state.deleteInsumo, inputType: state.type },
      { onSuccess, onError }
    );
  };

  const renderActions = (insumo) => (
    <DropdownMenu
      menu={[
        {
          title: 'Modificar',
          icon: <EyeOutlined />,
          onClick: () => state.setEditInsumo(insumo),
        },
        {
          title: 'Eliminar',
          icon: <UndoOutlined />,
          onClick: () => state.setDeleteInsumo(insumo.id),
        },
      ]}
    />
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader
          title="Adquisiciones"
          onRollback={() => history.goBack()}
          rightActions={
            <Button type="primary" onClick={() => setAddAdquisicion(true)}>
              AGREGAR ADQUISICIONES
            </Button>
          }
        />
      </Col>
      <Col span={4}>
        <FilterSider onChange={state.filters.set} />
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          dataSource={state.insumos?.results}
          columns={columns[state.type](renderActions)}
          showTotal={true}
          loading={state.isLoading}
          pagination={{
            ...state.pagination.get(),
            total: state.insumos?.count,
          }}
          onChange={(p, f, s) => {
            state.pagination.set(p);
            state.sorter.set(s);
          }}
        />
      </Col>
      <AdquisicionesModalAdd
        visible={addAdquisicion}
        inputType={state.type}
        onSuccess={() => state.refetch()}
        onCancel={() => setAddAdquisicion(false)}
        customAddForm={customAddForm}
      />
      <AdquisicionesModalEdit
        adquisicion={state.editInsumo}
        visible={!!state.editInsumo}
        inputType={state.type}
        success={state.reset}
        close={() => state.setEditInsumo(false)}
      />
      <DeleteModal
        title="Eliminar adquisición"
        bodyText={<span>esta adquisición</span>}
        isDeleting={isDeleting}
        visible={!!state.deleteInsumo}
        onCancel={() => state.setDeleteInsumo(false)}
        onConfirm={handleDelete}
        onSuccess={() => {
          state.reset();
          state.setDeleteInsumo(false);
        }}
        onSuccessMessage="Adquisición eliminada con éxito."
      />
    </Row>
  );
};

export default withAPI(InsumosRecordHistory);
