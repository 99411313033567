import React, { useState } from 'react';
import { InputNumber, Select, Form, Input } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const { Item: FormItem } = Form;

const defaultColumns = (registros, sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Registro',
    dataIndex: 'registro',
    key: 'registro__nombre',
    sorter,
    render: (v) => GENERAL.optionToLabel(registros, v),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'RP Padre',
    dataIndex: 'rp_padre',
    key: 'padre__rp',
    sorter,
  },
  {
    title: 'Peso actual',
    dataIndex: 'peso_actual',
    sorter,
    render: (w) => w && `${w} kg`,
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const partoFormColumns = (tiposParto, setNoChild, isNoChild) => [
  {
    title: 'Tipo de parto',
    dataIndex: 'tipo_parto',
    width: 300,
    render: (_t, _r, i) => (
      <FormItem
        name="birthType"
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: true,
            message: 'Seleccione el tipo de parto',
          },
        ]}
      >
        <Select
          style={{ width: '100%', minWidth: 200 }}
          onChange={(o) => setNoChild(i, o)}
          placeholder="Seleccionar"
          options={tiposParto}
        />
      </FormItem>
    ),
  },
  {
    title: 'Cantidad de crías',
    dataIndex: 'cantidad_hijos',
    width: 300,
    render: (_t, _r, i) => (
      <FormItem
        name="childs"
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: !isNoChild(i),
            message: 'Indique la cantidad de crías',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={1}
          max={9}
          placeholder="Indicar número"
          disabled={isNoChild(i)}
        />
      </FormItem>
    ),
  },
  {
    title: 'Condición corporal',
    dataIndex: 'condicion_corporal',
    render: (value, row) => (
      <FormItem
        name="condicion_corporal"
        style={{ marginBottom: 0 }}
        rules={[
          {
            type: 'string',
            pattern: /^[0-9?]*\.?[0-9]*$/,
            message: 'Ingrese condición corporal',
          },
        ]}
      >
        <Input placeholder="Ingrese condición corporal" />
      </FormItem>
    ),
  },
];

const PartoTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  registros = null,
  tiposParto = null,
  actions = null,
  ...props
}) => {
  const [noChilds, setNoChilds] = useState([]);
  const setNoChild = (index, type) => {
    const noChildTypes = [4, 5]; // 4=Abortion, 5=NoRegistration.
    if (noChildTypes.includes(type)) {
      form.setFieldsValue({ childs: undefined });
      setNoChilds((noChilds) => [...noChilds, index]);
    } else setNoChilds((noChilds) => noChilds.filter((i) => i !== index));
  };
  const isNoChild = (index) => noChilds.includes(index);
  let columns = defaultColumns(registros, true).concat(actionsColumn(actions));
  if (form) {
    columns = defaultColumns(registros).concat(
      partoFormColumns(tiposParto, setNoChild, isNoChild)
    );
  }
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default PartoTable;
