import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import _ from 'lodash';
import { withAPI } from 'modules/api';
import {
  EditOutlined,
  DeleteOutlined,
  DisconnectOutlined,
} from '@ant-design/icons';
import { List, Row, Col } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import {
  Table,
  Card,
  DeleteModal,
  DropdownMenu,
} from 'modules/core/components';
import EstablishmentMastersHeader from './EstablishmentMastersHeader';
import EstablishmentMastersModal from './EstablishmentMastersModal';

const filterSinAsignarItem = (items) => {
  return items
    .map((item) => ({
      ...item,
      isSinAsignar: MASTERS.isRodeoSinAsignar(item),
    }))
    .filter(
      (item) =>
        !item?.isSinAsignar ||
        (item?.isSinAsignar &&
          (item?.cantidad_hembra > 0 || item?.cantidad_macho > 0))
    );
};

const ESTABLISHMENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const EstablishmentMasters = ({
  api,
  masterType,
  masterName,
  columns = [],
  initialValues = (v) => v,
  renderForm = null,
  hideHeaderExtraActions = false,
  allowSinAsignar = false,
  actions = GENERAL.ACTIONS_LIST,
}) => {
  const [selectedItem, setSelectedItem] = useState(
    ESTABLISHMENT_ACTIONS_INITIAL_VALUES
  );

  const apiFetchFn =
    masterType !== MASTERS.PRODUCTIVA_TYPES.UBICACION
      ? api.fetchMasters
      : api.fetchUbicacionesOrdered;

  const { data, isLoading, refetch } = useQuery(
    [
      `Establecimientos::fetch${masterType}`,
      { masterType, params: { activo: true } },
    ],
    apiFetchFn,
    {
      select: (d) => {
        if (!allowSinAsignar) {
          return {
            ...d,
            results: filterSinAsignarItem(d.results),
          };
        }
        return d;
      },
    }
  );

  const { mutate: deleteEstablishmentMaster, isLoading: isDeleting } =
    useMutation(api.deleteMaster);

  const handleDelete = ({ onSuccess, onError }) => {
    deleteEstablishmentMaster(
      { masterType, id: selectedItem.item.id },
      { onSuccess, onError }
    );
  };

  const { mutate: deactivateEstablishmentMaster, isLoading: isDeactivating } =
    useMutation(api.editMaster);

  const handleDeactivate = ({ onSuccess, onError }) => {
    deactivateEstablishmentMaster(
      {
        masterType,
        id: selectedItem.item.id,
        body: { activo: false },
      },
      { onSuccess, onError }
    );
  };

  const resetSelectedItem = () =>
    setSelectedItem(ESTABLISHMENT_ACTIONS_INITIAL_VALUES);

  const renderActions = (item) => {
    const actionsHandler = (action) => setSelectedItem({ item, action });

    return _.compact([
      actions.includes(GENERAL.ACTIONS.MODIFICAR) && {
        title: 'Modificar',
        icon: <EditOutlined />,
        onClick: () => actionsHandler(GENERAL.ACTIONS.MODIFICAR),
      },
      actions.includes(GENERAL.ACTIONS.ELIMINAR) && {
        title: 'Eliminar',
        icon: <DeleteOutlined />,
        onClick: () => actionsHandler(GENERAL.ACTIONS.ELIMINAR),
      },

      actions.includes(GENERAL.ACTIONS.DESACTIVAR) && {
        title: 'Desactivar',
        icon: <DisconnectOutlined />,
        onClick: () => actionsHandler(GENERAL.ACTIONS.DESACTIVAR),
      },
    ]);
  };

  const renderList = () => {
    if (columns.length <= 0) {
      return (
        <Col span={24}>
          <List
            loading={isLoading}
            dataSource={data?.results}
            grid={{ gutter: 24, column: 4, xs: 1, sm: 2, md: 3 }}
            renderItem={(el) => {
              return (
                <List.Item>
                  <Card
                    title={el.nombre}
                    description={el?.descripcion}
                    {...(!el?.isSinAsignar && {
                      moreActions: renderActions(el),
                    })}
                  />
                </List.Item>
              );
            }}
          />
        </Col>
      );
    }
  };

  const renderTable = () => {
    const columnsWithActions = [
      ...columns,
      {
        title: 'Acciones',
        align: 'right ',
        render: (_, el) =>
          !el?.isSinAsignar && (
            <DropdownMenu title="Más acciones" menu={renderActions(el)} />
          ),
      },
    ];
    if (columns.length > 0) {
      return (
        <Col span={24}>
          <Table
            rowKey="id"
            dataSource={data?.results}
            columns={columnsWithActions}
            loading={isLoading}
            rowClassName={(record) => record.is_padre && 'bold'}
          />
        </Col>
      );
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <EstablishmentMastersHeader
          screenTitle={masterName.PLURAL}
          addTitle={`AGREGAR ${masterName.CAPITALIZE.toUpperCase()}`}
          onAdd={() =>
            setSelectedItem({ item: null, action: GENERAL.ACTIONS.CREAR })
          }
          hideExtraActions={hideHeaderExtraActions}
        />
      </Col>
      {renderList()}
      {renderTable()}
      <EstablishmentMastersModal
        visible={[GENERAL.ACTIONS.CREAR, GENERAL.ACTIONS.MODIFICAR].includes(
          selectedItem.action
        )}
        modalAction={selectedItem.action}
        initialValues={initialValues(selectedItem.item)}
        masterType={masterType}
        masterName={masterName}
        onSuccess={refetch}
        onCancel={resetSelectedItem}
        renderForm={renderForm}
      />
      <DeleteModal
        title={`Eliminar ${masterName.LOWER}`}
        bodyText={<b>{selectedItem.item?.nombre}</b>}
        isDeleting={isDeleting}
        visible={selectedItem.action === GENERAL.ACTIONS.ELIMINAR}
        onCancel={resetSelectedItem}
        onConfirm={handleDelete}
        onSuccess={() => {
          refetch();
          resetSelectedItem();
        }}
        onSuccessMessage={`${masterName.CAPITALIZE} eliminado con éxito.`}
      />
      <DeleteModal
        title={`Desactivar ${masterName.LOWER}`}
        okText="DESACTIVAR"
        isDeleting={isDeactivating}
        visible={selectedItem.action === GENERAL.ACTIONS.DESACTIVAR}
        onCancel={resetSelectedItem}
        onConfirm={handleDeactivate}
        onSuccess={() => {
          refetch();
          resetSelectedItem();
        }}
        onSuccessMessage={`${masterName.CAPITALIZE} desactivado con éxito.`}
      >
        <div>
          ¿Está seguro que desea desactivar <b>{selectedItem.item?.nombre}</b>?
        </div>
      </DeleteModal>
    </Row>
  );
};

export default withAPI(EstablishmentMasters);
