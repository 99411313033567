import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { ReactComponent as MobileIcon } from 'assets/home/mobile.svg';

const NotificationButton = ({
  api,
  showSync = true,
  showNotifications = true,
}) => {
  const history = useHistory();
  const { data: notifications } = useQuery(
    ['Notifications::fetchNotifications'],
    api.fetchNotifications,
    { enabled: showNotifications }
  );
  const notificationsToRead = notifications?.results.filter(
    (d) => !d.leida
  ).length;

  const { data: syncNotifications } = useQuery(
    ['Notifications::fetchSyncNotifications'],
    api.fetchSyncNotifications,
    { enabled: showSync }
  );

  return (
    <>
      {showSync && (
        <Badge className="app-header-icon-item" count={syncNotifications}>
          <MobileIcon
            alt="Sincronización"
            style={{ height: 25 }}
            onClick={() => history.push('/sincronizacion/')}
          />
        </Badge>
      )}
      {showNotifications && (
        <Badge className="app-header-icon-item" count={notificationsToRead}>
          <BellOutlined
            alt="Notificaciones"
            onClick={() => history.push('/notificaciones')}
          />
        </Badge>
      )}
    </>
  );
};

export default withAPI(NotificationButton);
