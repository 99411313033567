import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Select,
  Space,
  message as antMessage,
  Switch,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  MANAGEMENT,
  PRODUCTIVA,
  GENERAL,
  UNITS,
  MASTERS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import ServicioIndividualTable from './ServicioIndividualTable/ServicioIndividualTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const ServicioIndividual = ({ api }) => {
  const eventType = PRODUCTIVA.SERVICIO;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const {
    data: unitsServicio,
    isLoading,
    refetch,
  } = useQuery(
    ['Servicio::fetchByEvent', { eventType, unitType: UNITS.HEMBRAS, params }],
    api.fetchByEvent
  );

  const { data: asignacionesByUnits, isLoading: isLoadingAsignaciones } =
    useQuery(
      ['Servicio::fetchEventServicio', { unitsIds: selectedRows.keys }],
      api.fetchAsignacionesByUnits,
      { enabled: actionInProgress }
    );

  const { data: unitsMales, isLoading: gettingMales } = useQuery(
    [
      'Asignacion::fetchByEventMales',
      {
        eventType: PRODUCTIVA.ASIGNACION,
        unitType: UNITS.MACHOS,
      },
    ],
    api.fetchByEvent,
    { enabled: actionInProgress }
  );

  const { data: responsables } = useQuery(
    [
      'Servicio::ResponsablesMaster',
      { masterType: MASTERS.PRODUCTIVA_TYPES.RESPONSABLE },
    ],
    api.fetchMasters
  );
  const responsablesOptions = MASTERS.generateOptions(responsables?.results);

  const { mutate: ponerServicio, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const fecha = GENERAL.dateToBack(values.date);
        const unidades = _.map(values.selectedMales, (male, id) => ({
          fecha: fecha,
          tipo: values.type,
          ...(values.responsible && { responsable: values.responsible }),
          unidad: id,
          macho: male,
        }));
        const onSuccess = () => {
          antMessage.success('El evento se registro con éxito.');
          history.push(location.pathname);
        };
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        const params = {
          decrement_stock: GENERAL.valueBoolToStr(values.decrementSemenStock),
        };
        ponerServicio(
          { eventType, units: unidades, params },
          { onSuccess, onError }
        );
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Servicio individual"
        description={
          !actionInProgress
            ? 'Unidades aptas para recibir servicio: seleccioná las unidades y continuá para registrar el servicio'
            : 'Para realizar servicio a una unidad, completá con la fecha del evento y selecciona el macho del servicio'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.HEMBRAS,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider filters={[UnitFilter]} onChange={filters.set} />
      </Col>
      <Col xs={18} xxl={20}>
        <ServicioIndividualTable
          data={unitsServicio?.results}
          rowSelection={rowSelection}
          loading={isLoading}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsServicio?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Tipo de servicio"
              name="type"
              rules={[{ required: true, message: 'Seleccione tipo' }]}
              initialValue={'iatf'}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Seleccionar"
                options={PRODUCTIVA.OPTIONS[PRODUCTIVA.SERVICIO]}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Responsable"
              name="responsible"
              rules={[{ required: false, message: 'Seleccione responsable' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione responsable..."
                options={responsablesOptions}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <Space>
              <FormItem
                name="decrementSemenStock"
                valuePropName="checked"
                initialValue={false}
                noStyle
              >
                <Switch size="small" />
              </FormItem>
              <div>Decrementar stock de semen de machos asignados</div>
            </Space>
          </Col>
          <Col span={24}>
            <ServicioIndividualTable
              loading={isLoadingAsignaciones}
              data={asignacionesByUnits?.results}
              form={form}
              pagination={false}
              unitsMales={unitsMales?.results}
              isLoadingUnitsMales={gettingMales}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(ServicioIndividual);
