import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import { withAPI } from 'modules/api';
import { EllipsisOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Spin, Table } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { Card, Container, DropdownMenu } from 'modules/core/components';
import { UNITS } from 'modules/core/constants';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';

const HEIGHT_ROW_BASE = 170;
const HEIGHT_PER_TABLE_ROW = 47;

const EstablecimientoAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();
  const [isExporting, setIsExporting] = useState(false);
  const { data, isLoading } = useQuery(
    ['Analytics::fetchCategoriasByUbicacionRodeo', { params: filters.get() }],
    api.fetchCategoriasByUbicacionRodeo,
    { enabled: isActive }
  );

  const { mutate: exportEstablecimientoAnalytics } = useMutation(
    api.exportEstablecimientoAnalytics
  );
  const onExportEstablecimientoAnalytics = () => {
    setIsExporting(true);
    exportEstablecimientoAnalytics(
      { params: filters.get() },
      {
        onSuccess: (response) => {
          saveAs(response, `Analíticas unidades - Establecimiento.xlsx`);
          setIsExporting(false);
        },
        onError: ({ message: error }) => {
          if (error === '500') {
            message.error('Hubo un error al conectarse al servidor.');
          }
          setIsExporting(false);
        },
      }
    );
  };

  const generateCategoriesColumn = (unitType) => ({
    title: UNITS.TYPES_FORMATTED[unitType],
    align: 'center',
    children: UNITS.CATEGORIES_LIST[api.userSpecies][unitType].map((c) => ({
      title: UNITS.CATEGORIES_FORMATTED[c],
      dataIndex: c,
      width: '8%',
    })),
  });

  const columns = [
    { title: 'Rodeo', dataIndex: 'nombre', ellipsis: true },
    generateCategoriesColumn(UNITS.HEMBRAS),
    generateCategoriesColumn(UNITS.MACHOS),
  ];

  return (
    <Row gutter={[24, 32]} className="data-row">
      <Col span={24}>
        <Container>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[UnitFilter]}
            onChange={filters.set}
          />
          <DropdownMenu
            menu={[
              {
                title: 'Exportar a Excel',
                icon: <DownloadOutlined />,
                onClick: () => onExportEstablecimientoAnalytics(),
              },
            ]}
          >
            <Button icon={<EllipsisOutlined />} />
          </DropdownMenu>
        </Container>
      </Col>
      {isLoading && <Spin />}
      {data?.length <= 0 && <Col span={24}>No hay datos para mostrar.</Col>}
      {data?.map((el, ix) => (
        <Col span={24} key={ix}>
          <Card
            type="secondary"
            title={el?.titulo}
            style={{
              height: HEIGHT_ROW_BASE + HEIGHT_PER_TABLE_ROW * el?.data.length,
            }}
          >
            <Table
              rowKey="id"
              columns={columns}
              dataSource={el?.data}
              bordered
              loading={isLoading || isExporting}
              size="middle"
              pagination={false}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default withAPI(EstablecimientoAnalytics);
