import React from 'react';
import { Select, Form } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const { Item: FormItem } = Form;

const defaultColumns = (sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'Apodo Padre',
    dataIndex: 'apodo_padre',
    key: 'padre__apodo',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Peso actual',
    dataIndex: 'peso_actual',
    sorter,
    render: (w) => w && `${w} kg`,
  },
];
const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const servicioFormColumns = ({ unitsMales, isLoadingUnitsMales }) => [
  {
    title: 'RP',
    dataIndex: 'rp',
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Machos asignados',
    dataIndex: 'machos_asignados',
    render: (males, row) => (
      <FormItem
        name={[`selectedMales`, `${row.id}`]}
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: 'Seleccione el macho' }]}
      >
        <Select
          style={{ width: '100%', minWidth: 200 }}
          placeholder="Seleccionar"
          showSearch
          optionFilterProp="label"
          options={(males ? males : unitsMales)?.map((m) => ({
            value: m.id,
            label: m.apodo ?? m.rp,
          }))}
          loading={isLoadingUnitsMales}
        />
      </FormItem>
    ),
  },
];

const ServicioIndividualTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  actions = null,
  unitsMales = [],
  isLoadingUnitsMales = false,
  ...props
}) => {
  let columns = defaultColumns(true).concat(actionsColumn(actions));
  if (form) columns = servicioFormColumns({ unitsMales, isLoadingUnitsMales });
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default ServicioIndividualTable;
