import React from 'react';
import { useMutation } from 'react-query';
import { Modal, message as antMessage } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import './FincaClientResetModal.scss';

const FincaClientResetModal = ({ api, est, visible, close, success }) => {
  const { mutate: resetEst, isLoading: resetting } = useMutation(
    api.resetEstablishment
  );
  const onResetEst = () => {
    const onSuccess = () => {
      success();
      antMessage.success(
        `Los datos del establecimiento ${est?.name} fueron restablecidos con éxito.`
      );
    };
    const onError = ({ message }) => {
      switch (message) {
        case '403':
          antMessage.error(
            `El tenant del establecimiento ${est?.name} no puede ser restablecido.`
          );
          break;
        default:
          antMessage.error('ERROR');
      }
    };
    resetEst({ estId: est.id }, { onSuccess, onError });
  };
  return (
    <Modal
      title={
        <>
          <ReloadOutlined /> {'Restablecer datos del establecimiento'}
        </>
      }
      className="finca-client-reset-modal"
      cancelText="CANCELAR"
      okText="RESTABLECER"
      confirmLoading={resetting}
      open={visible}
      onCancel={close}
      onOk={onResetEst}
    >
      <div className="est-reset-text">
        ¿Desea restablecer los datos del establecimiento{' '}
        <span className="est-reset-text-bold">{est?.name}</span>?
      </div>
    </Modal>
  );
};

export default withAPI(FincaClientResetModal);
