import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Form, DatePicker, message as antMessage } from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  MANAGEMENT,
  PRODUCTIVA,
  UNITS,
  GENERAL,
  MASTERS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import ServicioColectivoTable from './ServicioColectivoTable/ServicioColectivoTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const ServicioColectivo = ({ api }) => {
  const eventType = PRODUCTIVA.SERVICIOCOLECTIVO;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );

  const [selectedRowsFemale, setSelectedRowsFemale] = useState({
    keys: [],
    rows: [],
  });
  const [selectedRowKeysMale, setSelectedRowKeysMale] = useState([]);
  const [paginationMales, setPaginationMales] = useState({
    current: 1,
    pageSize: 50,
  });
  const [maleSearch, setMaleSearch] = useState(null);
  const [form] = Form.useForm();

  const { data: options, isLoading: fetchingOptions } = useQuery(
    [
      'Masters::Servicio',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
          MASTERS.PRODUCTIVA_TYPES.RAZA,
        ],
      },
    ],
    api.fetchMastersFormOptions
  );
  const registros = options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO];
  const razas = options?.[MASTERS.PRODUCTIVA_TYPES.RAZA];

  const {
    data: unitsColectivo,
    isLoading,
    refetch,
  } = useQuery(
    [
      'Servicio::fetchByEventFemales',
      { eventType, unitType: UNITS.HEMBRAS, params },
    ],
    api.fetchByEvent
  );
  const {
    data: unitsMales,
    isLoading: gettingMales,
    refetch: refetchMales,
  } = useQuery(
    [
      'ServicioColectivo::fetchByEventMales',
      {
        eventType,
        unitType: UNITS.MACHOS,
        params: {
          search: maleSearch,
          page: paginationMales.current,
          page_size: paginationMales.pageSize,
        },
      },
    ],
    api.fetchByEvent,
    { enabled: actionInProgress }
  );
  const { mutate: aplicarServicio, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRowsFemale.keys);
  const onSelectChangeFemale = (selectedRowKeys, selectedRows) => {
    setSelectedRowsFemale({ keys: selectedRowKeys, rows: selectedRows });
  };
  const onSelectChangeMale = (selectedRowKeys) => {
    setSelectedRowKeysMale(selectedRowKeys);
  };
  const rowSelectionFemale = {
    selectedRowKeys: selectedRowsFemale.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChangeFemale,
  };
  const rowSelectionMale = {
    selectedRowKeys: selectedRowKeysMale,
    preserveSelectedRowKeys: true,
    onChange: onSelectChangeMale,
  };

  const onAction = () => {
    refetchMales();
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRowsFemale({ keys: [], rows: [] });
    setSelectedRowKeysMale([]);
    form.resetFields();
    setActionInProgress(false);
  };

  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const fecha = GENERAL.dateToBack(values.date);
        const fecha_inicio = GENERAL.dateToBack(values.startDate);
        const assignedUnits = selectedRowsFemale.keys.map((f) => ({
          fecha,
          fecha_inicio,
          unidad: f,
          machos: selectedRowKeysMale,
        }));
        const onSuccess = () => {
          antMessage.success('El evento se registro con éxito.');
          history.push(location.pathname);
        };
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        aplicarServicio(
          { eventType, units: assignedUnits },
          { onSuccess, onError }
        );
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Servicio colectivo"
        description={
          !actionInProgress
            ? 'Unidades aptas para recibir servicio: seleccioná las unidades y continuá para registrar el servicio'
            : 'Para realizar servicio a una unidad, completá con la fecha del evento y seleccioná los machos del servicio'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.HEMBRAS,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider filters={[UnitFilter]} onChange={filters.set} />
      </Col>
      <Col xs={18} xxl={20}>
        <ServicioColectivoTable
          data={unitsColectivo?.results}
          rowSelection={rowSelectionFemale}
          loading={isLoading || fetchingOptions}
          registros={registros}
          razas={razas}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsColectivo?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha de fin"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Fecha de inicio"
              name="startDate"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <ServicioColectivoTable
              form={form}
              registros={registros}
              data={selectedRowsFemale.rows}
              pagination={false}
              maleUnits={unitsMales}
              maleSelection={rowSelectionMale}
              maleLoading={gettingMales}
              maleRefetch={setMaleSearch}
              malePagination={{ ...paginationMales, total: unitsMales?.count }}
              malePaginationSet={setPaginationMales}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(ServicioColectivo);
