import React from 'react';
import { Select, Form, Input } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, MASTERS, PRODUCTIVA, UNITS } from 'modules/core/constants';

const { Item: FormItem } = Form;

const defaultColumns = (registros, sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Registro',
    dataIndex: 'registro',
    key: 'registro__nombre',
    sorter,
    render: (v) => GENERAL.optionToLabel(registros, v),
  },
  {
    title: 'Peso actual',
    dataIndex: 'peso_actual',
    sorter,
    render: (w) => w && `${w} kg`,
  },
];
const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const preniezFormColumns = [
  {
    title: 'Tipo de embrión',
    dataIndex: 'tipo_embrion',
    render: (value, row) => (
      <FormItem
        initialValue={value}
        name={[`embrionTypes`, `${row.id}`]}
        style={{ marginBottom: 0 }}
      >
        <Select
          style={{ width: '100%', minWidth: 200 }}
          placeholder="Seleccionar"
          options={PRODUCTIVA.OPTIONS[PRODUCTIVA.PRENIEZ].TYPE}
        />
      </FormItem>
    ),
  },
  {
    title: 'Condición corporal',
    dataIndex: 'condicion_corporal',
    render: (value, row) => (
      <FormItem
        name={[`condicion_corporal`, `${row.id}`]}
        style={{ marginBottom: 0 }}
        rules={[
          {
            type: 'string',
            pattern: /^[0-9?]*\.?[0-9]*$/,
            message: 'Ingrese condición corporal',
          },
        ]}
      >
        <Input placeholder="Ingrese condición corporal" />
      </FormItem>
    ),
  },
  {
    title: 'CUT',
    dataIndex: 'cut',
    render: (value, row) => (
      <FormItem name={[`cut`, `${row.id}`]} style={{ marginBottom: 0 }}>
        <Select
          style={{ width: '100%', minWidth: 200 }}
          optionFilterProp="label"
          placeholder="Seleccionar"
          options={MASTERS.BOOLEAN_OPTIONS}
        />
      </FormItem>
    ),
  },
];

const PreniezTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  registros = null,
  actions = null,
  ...props
}) => {
  let columns = defaultColumns(registros, true).concat(actionsColumn(actions));
  if (form) columns = defaultColumns(registros).concat(preniezFormColumns);
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default PreniezTable;
