import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Form, DatePicker, message as antMessage } from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  MANAGEMENT,
  PRODUCTIVA,
  GENERAL,
  UNITS,
  MASTERS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';

import SeleccionMachosTable from './SeleccionMachosTable/SeleccionMachosTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const SeleccionMachos = ({ api }) => {
  const eventType = PRODUCTIVA.SELECCION_MACHOS;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const { data: options, isLoading: fetchingOptions } = useQuery(
    ['Masters::Registro', { masterTypes: [MASTERS.PRODUCTIVA_TYPES.REGISTRO] }],
    api.fetchMastersFormOptions
  );
  const registros = options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO];

  const {
    data: unitsSeleccion,
    isLoading,
    refetch,
  } = useQuery(
    ['Seleccion::fetchByEvent', { eventType, unitType: UNITS.MACHOS, params }],
    api.fetchByEvent
  );
  const { mutate: seleccionar, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const fecha = GENERAL.dateToBack(values.date);
        const unidades = _.map(values.newCategories, (category, id) => ({
          fecha: fecha,
          unidad: id,
          categoria: category,
        }));
        const onSuccess = () => {
          antMessage.success('El evento se registro con éxito.');
          history.push(location.pathname);
        };
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        seleccionar({ eventType, units: unidades }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Selección de machos"
        description={
          !actionInProgress
            ? 'Unidades aptas para realizar selección'
            : 'Para realizar la selección, completá la fecha y determiná la categoría que corresponda'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.MACHOS,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider
          filters={[UnitFilter]}
          filtersProps={{ unitType: UNITS.MACHOS }}
          onChange={filters.set}
        />
      </Col>
      <Col xs={18} xxl={20}>
        <SeleccionMachosTable
          data={unitsSeleccion?.results}
          rowSelection={rowSelection}
          loading={isLoading || fetchingOptions}
          registros={registros}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsSeleccion?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <SeleccionMachosTable
              loading={isLoading}
              data={selectedRows.rows}
              form={form}
              registros={registros}
              userSpecies={api.userSpecies}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(SeleccionMachos);
