import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Row, Col, Spin, DatePicker } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { Card } from 'modules/core/components';
import {
  EconomicPieChart,
  EconomicLineChart,
  EconomicTable,
} from './components';

const { RangePicker } = DatePicker;

const HEIGHT_ROW_1 = 780;
const HEIGHT_ROW_2 = 400;
const HEIGHT_ROW_3 = 500;

const HomeDashEconomico = ({ api }) => {
  const [selectedDates, setSelectedDates] = useState(null);
  const { data: actualCurrency, isLoading } = useQuery(
    ['Economica::fetchDivisaActual'],
    api.fetchDivisaActual
  );

  const onChangeDates = (dates) => {
    if (dates) {
      setSelectedDates({
        from_fecha: GENERAL.dateToBack(dates[0]),
        to_fecha: GENERAL.dateToBack(dates[1]),
      });
    } else setSelectedDates(null);
  };

  if (isLoading) return <Spin />;

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <RangePicker
            onChange={onChangeDates}
            format={GENERAL.DATE_FORMAT.FRONT}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <Card
            title="Resultado económico"
            description="Reporte del resultado ecónomico del establecimiento"
            type="secondary"
            style={{ height: HEIGHT_ROW_1 }}
          >
            <EconomicTable
              currency={actualCurrency?.currency}
              fetch={api.fetchEconomicaDashboardTable}
              params={{ ...selectedDates }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col xs={24} lg={12}>
          <EconomicPieChart
            title="Egresos"
            description="Conoce los egresos divididos por tipo de egresos."
            height={HEIGHT_ROW_2}
            fetch={api.fetchEconomicaDashboardEgresosChart}
            params={{ ...selectedDates }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <EconomicPieChart
            title="Ingresos"
            description="Conoce los ingresos divididos por tipo de ingreso."
            height={HEIGHT_ROW_2}
            fetch={api.fetchEconomicaDashboardIngresosChart}
            params={{ ...selectedDates }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <EconomicLineChart
            title="Ingresos vs egresos"
            description="Comparacion de los ingresos y egresos del año."
            height={HEIGHT_ROW_3}
            currency={actualCurrency?.currency}
            fetch={api.fetchEconomicaDashboardAnualChart}
          />
        </Col>
      </Row>
    </>
  );
};

export default withAPI(HomeDashEconomico);
