import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  message as antMessage,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  NUTRICIONAL,
  MASTERS,
  GENERAL,
  UNITS,
  MANAGEMENT,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import DietaTable from './DietaTable/DietaTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const Destete = ({ api }) => {
  const eventType = NUTRICIONAL.DIETA;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const {
    data: unitsDieta,
    isLoading,
    refetch,
  } = useQuery(
    ['Dieta::fetchByEvent', { eventType: NUTRICIONAL.GENERIC_EVENT, params }],
    api.fetchByEvent
  );

  const { data: disponibilidadPasto } = useQuery(
    [
      'Nutricional::DisponibilidadPastoMaster',
      { masterType: MASTERS.NUTRICIONAL_TYPES.DISPONIBILIDAD_PASTO },
    ],
    api.fetchMasters
  );
  const disponibilidadPastoOptions = MASTERS.generateOptions(
    disponibilidadPasto?.results
  );

  const { data: suplementos } = useQuery(
    ['Nutricional::Suplementos', { inputType: NUTRICIONAL.SUPLEMENTO }],
    api.fetchInsumo
  );
  const suplementosOptions = MASTERS.generateOptions(suplementos?.results);

  const { mutate: registrarDieta, isLoading: registering } = useMutation(
    api.addNutricionalEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const dietas = {
          fecha: GENERAL.dateToBack(values.startDate),
          disponibilidad_pasto: values.grassAvailability,
          suplemento: values.supplement,
          unidad: selectedRows.keys.map((el) => ({
            unidad_id: el,
            cantidad_suplemento: values.supplementAmount[el]
              ? values.supplementAmount[el]
              : 0,
            peso_inicio: values.initialWeight[el]
              ? values.initialWeight[el]
              : 0,
          })),
        };
        const onSuccess = () => {
          antMessage.success('El evento se registro con éxito.');
          history.push(location.pathname);
        };
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        registrarDieta({ eventType, body: dietas }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Dieta"
        description={
          !actionInProgress
            ? 'Unidades disponibles para dieta'
            : `${selectedRows.rows.length} unidades seleccionadas para dieta`
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.NUTRICIONAL,
          unitType: UNITS.UNIDAD,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider
          filters={[UnitFilter]}
          filtersProps={{ unitType: [UNITS.HEMBRAS, UNITS.MACHOS] }}
          onChange={filters.set}
        />
      </Col>
      <Col xs={18} xxl={20}>
        <DietaTable
          data={unitsDieta?.results}
          rowSelection={rowSelection}
          loading={isLoading}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsDieta?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha de inicio"
              name="startDate"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Disponibilidad de pasto"
              name="grassAvailability"
              rules={[{ required: true, message: 'Indique disponibilidad' }]}
            >
              <Select
                placeholder="Seleccionar"
                style={{ width: '100%' }}
                options={disponibilidadPastoOptions}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Suplemento"
              name="supplement"
              rules={[{ required: false, message: 'Seleccione suplemento' }]}
            >
              <Select
                placeholder="Seleccionar"
                style={{ width: '100%' }}
                options={suplementosOptions}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <DietaTable
              loading={isLoading}
              data={selectedRows.rows}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(Destete);
