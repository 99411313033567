import React from 'react';
import { Input, Form } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const { Item: FormItem } = Form;

const defaultColumns = (sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'RP Padre',
    dataIndex: 'rp_padre',
    key: 'padre__rp',
    sorter,
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Peso actual',
    dataIndex: 'peso_actual',
    sorter,
    render: (w) => w && `${w} kg`,
  },
];

const desteteFormColumns = [
  {
    title: 'Peso al destete',
    dataIndex: 'peso_destete',
    render: (value, row) => (
      <FormItem
        initialValue={value}
        name={[`newWeights`, `${row.id}`]}
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: true,
            type: 'string',
            pattern: /^[0-9?]*\.?[0-9]*$/,
            message: 'Ingrese el peso del destete',
          },
        ]}
      >
        <Input placeholder="Ingrese peso en kgs" />
      </FormItem>
    ),
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const DesteteTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  actions = null,
  ...props
}) => {
  let columns = defaultColumns(true).concat(actionsColumn(actions));
  if (form) columns = defaultColumns().concat(desteteFormColumns);
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default DesteteTable;
