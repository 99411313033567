import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
  FileDoneOutlined,
  PlayCircleOutlined,
  DownloadOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { Modal, Steps, Space, message } from 'antd';
import { withAPI } from 'modules/api';
import { ModalTitle, UploadModal } from 'modules/core/components';
import './UnitsSyncModal.scss';

const STEPS_ID = {
  PROCESAR_IMG: 0,
  VALIDAR_XLS: 1,
};
const STEPS = {
  [STEPS_ID.PROCESAR_IMG]: {
    title: 'Procesar imagen',
    icon: <PlayCircleOutlined />,
    okText: 'PROCESAR IMAGEN',
  },
  [STEPS_ID.VALIDAR_XLS]: {
    title: 'Validar archivo',
    icon: <FileDoneOutlined />,
    okText: 'REALIZAR CARGA MASIVA',
  },
};
const STEPS_LIST = Object.values(STEPS);
const firstStep = STEPS_ID.PROCESAR_IMG;
const calculateItemStep = (item) => {
  if (item?.xls_file) {
    return STEPS_ID.VALIDAR_XLS;
  }
  return STEPS_ID.PROCESAR_IMG;
};

const UPLOAD_FILE_INIT = {
  visible: false,
  filename: null,
  params: {},
  errorData: {},
};

const UnitsSyncModal = ({ api, open = false, item, onCancel, refetch }) => {
  const [itemFileLink, setItemFileLink] = useState(null);
  const [currentStep, setCurrentStep] = useState(firstStep);
  const [uploadFile, setUploadFile] = useState(UPLOAD_FILE_INIT);

  useEffect(() => {
    const itemStep = calculateItemStep(item);
    setCurrentStep(itemStep);
  }, [item]);

  const itemXlsFile = item?.xls_file || itemFileLink;

  const handleOnCancel = () => {
    onCancel();
    setCurrentStep(firstStep);
    setItemFileLink(null);
    setUploadFile(UPLOAD_FILE_INIT);
    if (currentStep === STEPS_ID.VALIDAR_XLS) {
      refetch();
    }
  };

  const { mutate: mobileUnitsSyncProcessImage, isLoading: isProcessingImage } =
    useMutation(api.mobileUnitsSyncProcessImage);
  const handleProcessImage = () => {
    mobileUnitsSyncProcessImage(
      { fileId: item.id },
      {
        onSuccess: (data) => {
          setItemFileLink(data?.xls_file);
          setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
        },
        onError: handleOnError,
      }
    );
  };

  const { mutate: mobileUnitsSyncProcessXls, isLoading: isProcessingXls } =
    useMutation(api.mobileUnitsSyncProcessXls);
  const handleProcessXls = () => {
    mobileUnitsSyncProcessXls(
      { fileId: item.id },
      {
        onSuccess: (data) => {
          if (data.error) {
            setUploadFile({
              visible: true,
              params: { fileId: item.id },
              filename: getFilenameFromURL(itemXlsFile),
              errorData: data,
            });
          } else {
            handleOnCancel();
            message.success('Archivo procesado con éxito.');
          }
        },
        onError: handleOnError,
      }
    );
  };

  const handleNextStep = () => {
    switch (currentStep) {
      case STEPS_ID.PROCESAR_IMG:
        handleProcessImage();
        break;
      case STEPS_ID.VALIDAR_XLS:
        handleProcessXls();
        break;
      default:
        handleOnCancel();
        break;
    }
  };

  const RENDER_STEPS = {
    [STEPS_ID.PROCESAR_IMG]: <StepProcessImg imageUrl={item?.image_file} />,
    [STEPS_ID.VALIDAR_XLS]: <StepValidateXls fileUrl={itemXlsFile} />,
  };

  return (
    <Modal
      title={
        <ModalTitle icon={<FileAddOutlined />} title="Carga de unidades" />
      }
      width={600}
      className="units-sync-modal"
      open={open}
      okText={STEPS[currentStep].okText || 'SIGUIENTE'}
      onOk={handleNextStep}
      okButtonProps={{
        disabled: item && calculateItemStep(item) > currentStep,
      }}
      confirmLoading={isProcessingImage || isProcessingXls}
      onCancel={handleOnCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      forceRender={true}
    >
      <Steps
        size="small"
        items={STEPS_LIST}
        current={currentStep}
        onChange={(v) => calculateItemStep(item) >= v && setCurrentStep(v)}
      />
      <div className="unit-sync-steps-container">
        {RENDER_STEPS[currentStep]}
      </div>
      <UploadModal
        title={uploadFile.filename}
        inputFormName="xls_file"
        apiFn={api.mobileUnitsSyncProcessXls}
        params={uploadFile.params}
        visible={uploadFile.visible}
        close={() => setUploadFile(UPLOAD_FILE_INIT)}
        onUpload={handleOnCancel}
        errorData={uploadFile.errorData}
      />
    </Modal>
  );
};

const StepDescription = ({ text = '' }) => {
  return <div className="step-description">{text}</div>;
};

const StepProcessImg = ({ imageUrl }) => {
  return (
    <a href={imageUrl} target="_blank" rel="noreferrer">
      <Space>
        <img
          className="img-preview"
          src={imageUrl}
          alt="Imagen a procesar"
          width="100"
          height="100"
        />
        <StepDescription text="Click aquí para visualizar la imagen en tamaño completo y validar los datos." />
      </Space>
    </a>
  );
};

const StepValidateXls = ({ fileUrl }) => {
  return (
    <a href={fileUrl} target="_blank" rel="noreferrer">
      <Space direction="vertical" align="center">
        <DownloadOutlined style={{ fontSize: 48 }} />
        <StepDescription text="Descarga el archivo generado para visualizar y validar las unidades a dar de alta." />
      </Space>
    </a>
  );
};

const handleOnError = ({ description }) => message.error(description);

const getFilenameFromURL = (url = '') => {
  return url
    .split('?')[0]
    .split('/')
    .pop()
    .replace('.xlsx', '')
    .replace('.xls', '');
};

export default withAPI(UnitsSyncModal);
