import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import moment from 'moment';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  message as antMessage,
} from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import {
  DeleteModal,
  DropdownMenu,
  ModalTitle,
  ScreenHeader,
  Table,
} from 'modules/core/components';
import TareaModal from './TareaModal';

const TASK_MODAL_INITIAL_VALUES = { item: null, action: null };
const TASKS_ACTIONS_REALIZAR = 'done';
const TASKS_PENDING = 'tareas-pendientes';
const TASKS_DONE = 'tareas-realizadas';

const Tareas = ({ api }) => {
  const [activeTab, setActiveTab] = useState(TASKS_PENDING);
  const { pagination, sorter, params } = usePaginationWithFilters();
  const [selectedItem, setSelectedItem] = useState(TASK_MODAL_INITIAL_VALUES);
  const [taskDoneDate, setTaskDoneDate] = useState(moment());

  const {
    data: tareasRealizadas,
    isLoading: loadingTareasRealizadas,
    refetch: refetchTareasRealizadas,
  } = useQuery(
    [
      `Establecimientos::fetchTareasRealizadas`,
      { params: { ...params, realizado: true } },
    ],
    api.fetchTareas,
    { enabled: activeTab === TASKS_DONE }
  );

  const {
    data: tareasPendientes,
    isLoading: loadingTareasPendientes,
    refetch: refetchTareasPendientes,
  } = useQuery(
    [
      `Establecimientos::fetchTareasPendientes`,
      { params: { ...params, realizado: false } },
    ],
    api.fetchTareas,
    { enabled: activeTab === TASKS_PENDING }
  );

  const { mutate: deleteTarea, isLoading: isDeleting } = useMutation(
    api.deleteTarea
  );
  const handleDelete = ({ onSuccess, onError }) => {
    deleteTarea(selectedItem.item.id, { onSuccess, onError });
  };

  const actionsHandler = ({ item = null, action }) =>
    setSelectedItem({ item, action });

  const resetSelectedItem = () => setSelectedItem(TASK_MODAL_INITIAL_VALUES);

  const { mutate: changeTaskStatus, isLoading: isChangingStatus } = useMutation(
    api.setTareaRealizada
  );
  const onChangeTaskStatus = (taskId, successMsg = '') => {
    const onSuccess = () => {
      antMessage.success(successMsg);
      resetSelectedItem();
      refetchTareasRealizadas();
      refetchTareasPendientes();
    };
    const onError = ({ message, description }) => {
      if (message === '400') antMessage.error(description, 5);
    };
    changeTaskStatus(
      { taskId, data: { fecha_realizacion: GENERAL.dateToBack(taskDoneDate) } },
      { onSuccess, onError }
    );
  };

  const actionsMenuPendientes = (task) => {
    return [
      {
        title: 'Modificar',
        icon: <EditOutlined />,
        onClick: () =>
          actionsHandler({
            item: task,
            action: GENERAL.ACTIONS.MODIFICAR,
          }),
      },
      {
        title: 'Realizada',
        icon: <CheckCircleOutlined />,
        onClick: () =>
          actionsHandler({
            item: task,
            action: TASKS_ACTIONS_REALIZAR,
          }),
      },
      {
        title: 'Eliminar',
        icon: <DeleteOutlined />,
        onClick: () =>
          actionsHandler({
            item: task,
            action: GENERAL.ACTIONS.ELIMINAR,
          }),
      },
    ];
  };

  const actionsMenuRealizadas = (task) => {
    return [
      {
        title: 'Deshacer',
        icon: <UndoOutlined />,
        onClick: () => onChangeTaskStatus(task?.id, 'Tarea revertida'),
      },
    ];
  };

  const items = [
    {
      key: 'tareas-pendientes',
      label: 'Pendientes',
      children: (
        <TareasTable
          dataSource={tareasPendientes}
          loading={loadingTareasPendientes}
          actionsMenu={actionsMenuPendientes}
          pagination={pagination}
          sorter={sorter}
        />
      ),
    },
    {
      key: 'tareas-realizadas',
      label: 'Realizadas',
      children: (
        <TareasTable
          dataSource={tareasRealizadas}
          loading={loadingTareasRealizadas}
          pagination={pagination}
          sorter={sorter}
          showDoneDate
          actionsMenu={actionsMenuRealizadas}
        />
      ),
    },
  ];
  const onChangeTab = (key) => {
    setActiveTab(key);
    pagination.set({ ...pagination.get(), current: 1 });
    sorter.set(null);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader
          title="Tareas"
          rightActions={
            <Button
              type="primary"
              onClick={() =>
                setSelectedItem({ item: null, action: GENERAL.ACTIONS.CREAR })
              }
            >
              AGREGAR TAREA
            </Button>
          }
        />
      </Col>
      <Col span={24}>
        <Tabs items={items} onChange={onChangeTab} />
      </Col>
      <TareaModal
        visible={[GENERAL.ACTIONS.CREAR, GENERAL.ACTIONS.MODIFICAR].includes(
          selectedItem.action
        )}
        initialValues={selectedItem.item}
        onCancel={resetSelectedItem}
        onConfirm={handleDelete}
        onSuccess={refetchTareasPendientes}
      />
      <DeleteModal
        title="Eliminar tarea"
        isDeleting={isDeleting}
        visible={selectedItem.action === GENERAL.ACTIONS.ELIMINAR}
        onConfirm={handleDelete}
        onCancel={resetSelectedItem}
        onSuccess={() => {
          resetSelectedItem();
          refetchTareasPendientes();
        }}
        bodyText="esta tarea"
        onSuccessMessage={'La tarea se eliminó con éxito.'}
      />
      <Modal
        title={
          <ModalTitle icon={<CheckCircleOutlined />} title="Realizar tarea" />
        }
        open={selectedItem.action === TASKS_ACTIONS_REALIZAR}
        cancelText="CANCELAR"
        okText="REALIZAR"
        confirmLoading={isChangingStatus}
        onCancel={resetSelectedItem}
        onOk={() =>
          onChangeTaskStatus(selectedItem.item.id, 'Tarea completada')
        }
      >
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          ¿Está seguro que desea marcar esta tarea como realizada?
          <DatePicker
            style={{ width: '100%' }}
            allowClear={false}
            defaultValue={taskDoneDate}
            format={GENERAL.DATE_FORMAT.FRONT}
            onChange={setTaskDoneDate}
          />
        </Space>
      </Modal>
    </Row>
  );
};

const TareasTable = ({
  dataSource = [],
  loading,
  pagination,
  sorter,
  showDoneDate = false,
  actionsMenu = () => [],
}) => {
  const columns = _.compact([
    {
      title: 'Fecha creación',
      dataIndex: 'fecha_creacion',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Fecha límite',
      dataIndex: 'fecha_limite',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      sorter: true,
      ellipsis: true,
      width: '60%',
    },
    {
      title: 'Responsable',
      dataIndex: 'responsable_value',
      key: 'responsable__nombre',
      sorter: true,
    },
    {
      title: 'Prioridad',
      dataIndex: 'prioridad_value',
      key: 'prioridad_order',
      sorter: true,
      render: (v) => (
        <Tag color={MASTERS.PRIORIDADES_BADGES[v?.toLocaleLowerCase()]}>
          {v}
        </Tag>
      ),
    },
    showDoneDate && {
      title: 'Fecha realización',
      dataIndex: 'fecha_realizacion',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_, task) => <DropdownMenu menu={actionsMenu(task)} />,
    },
  ]);

  return (
    <Table
      rowKey="id"
      dataSource={dataSource?.results}
      columns={columns}
      loading={loading}
      showTotal={true}
      pagination={{ ...pagination.get(), total: dataSource?.count }}
      onChange={(p, f, s) => {
        pagination.set(p);
        sorter.set(s);
      }}
    />
  );
};

export default withAPI(Tareas);
