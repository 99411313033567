import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { Button, Modal, Form, Input, message } from 'antd';

const { Item: FormItem } = Form;

const ChangePasswordModal = ({ api }) => {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const { mutate: editUserPassword, isLoading: isEditingPassword } =
    useMutation(api.editUserPassword, {
      onError: () => {
        message.error('Hubo un problema al cambiar la contraseña', 5);
      },
      onSuccess: () => {
        message.success('La contraseña fue modificada con éxito.');
        onCancel();
      },
    });

  const confirmPasswordValidator = (_, value) => {
    return value && value !== form.getFieldValue('password')
      ? Promise.reject(new Error('Las contraseñas no coinciden!'))
      : Promise.resolve();
  };

  const validateToConfirmPassword = (_, value) => {
    return value && value === form.getFieldValue('old_password')
      ? Promise.reject(
          new Error('La contraseña debe ser distinta a la anterior')
        )
      : Promise.resolve();
  };

  const onConfirmation = () => {
    form
      .validateFields()
      .then((values) => {
        editUserPassword(values);
      })
      .catch((info) => console.log('Validate Failed:', info));
  };

  const onCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  return (
    <>
      <Button type="link" onClick={() => setShowModal(true)}>
        Cambiar contraseña
      </Button>
      <Modal
        open={showModal}
        title="Cambiar contraseña"
        forceRender
        okText="Cambiar"
        cancelText="Cancelar"
        onCancel={onCancel}
        onOk={onConfirmation}
        okButtonProps={{ loading: isEditingPassword }}
      >
        <Form form={form} layout="vertical" requiredMark={false}>
          <FormItem
            label="Contraseña actual"
            name="old_password"
            rules={[
              {
                required: true,
                message: 'Por favor ingresar constraseña actual',
              },
            ]}
          >
            <Input.Password placeholder="Contraseña actual" />
          </FormItem>
          <FormItem
            label="Nueva contraseña"
            name="password"
            rules={[
              {
                required: true,
                message: 'Por favor ingresar nueva constraseña',
              },
              { validator: validateToConfirmPassword },
            ]}
          >
            <Input.Password placeholder="Nueva contraseña" />
          </FormItem>
          <FormItem
            label="Confirmar contraseña"
            name="password_confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Por favor ingresar nueva constraseña',
              },
              { validator: confirmPasswordValidator },
            ]}
          >
            <Input.Password placeholder="Confirmar contraseña" />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default withAPI(ChangePasswordModal);
