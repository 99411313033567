import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import _ from 'lodash';
import { withAPI } from 'modules/api';
import { Modal, Form, message } from 'antd';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import { ModalTitle } from 'modules/core/components';

const InsumoModal = ({
  api,
  visible,
  inputType,
  masterName,
  onSuccess,
  onCancel,
  afterClose,
  initialValues = {},
  renderForm = null,
}) => {
  const [form] = Form.useForm();
  const { mutate: add, isLoading: isAdding } = useMutation(api.addInsumo);
  const { mutate: edit, isLoading: isEditing } = useMutation(api.editInsumo);

  const hasInitialValues = !_.isEmpty(initialValues);

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const handleOnCancel = () => {
    clearForm();
    onCancel();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const requestParams = { body: values, inputType };
        const afterCreatingOrEditing = ({ isAdding = false }) => {
          onSuccess();
          if (isAdding) {
            message.success(`Se agrego insumo ${masterName.LOWER}.`);
          }
          handleOnCancel();
        };
        const onError = ({ message: error }) => {
          if (error === '400')
            message.error(`Nombre de ${masterName.LOWER} existente`, 5);
        };

        if (hasInitialValues) {
          edit(
            { ...requestParams, id: initialValues.id },
            { onSuccess: afterCreatingOrEditing, onError }
          );
        } else {
          add(
            { ...requestParams },
            {
              onSuccess: () => afterCreatingOrEditing({ isAdding: true }),
              onError,
            }
          );
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        hasInitialValues ? (
          <ModalTitle
            icon={<EditOutlined />}
            title={`Editar ${masterName.LOWER}`}
          />
        ) : (
          <ModalTitle
            icon={<FileAddOutlined />}
            title={`Agregar ${masterName.LOWER}`}
          />
        )
      }
      forceRender
      cancelText="CANCELAR"
      okText={hasInitialValues ? 'EDITAR' : 'AGREGAR'}
      confirmLoading={isAdding || isEditing}
      open={visible}
      onCancel={handleOnCancel}
      onOk={onConfirm}
      afterClose={afterClose}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
      >
        {renderForm()}
      </Form>
    </Modal>
  );
};

export default withAPI(InsumoModal);
