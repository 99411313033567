import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import _ from 'lodash';
import moment from 'moment';
import {
  Col,
  Form,
  Modal,
  Row,
  Space,
  Button,
  DatePicker,
  Select,
  message,
} from 'antd';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import { DeleteModal, ModalTitle } from 'modules/core/components';
import { ESTABLISHMENT, GENERAL, MASTERS, UNITS } from 'modules/core/constants';

// This is becaouse backend use different keys for ubicaciones (ubicaciongeografica) master and sending data (also with rodeo)
const formatPlanificacionObjetivos = (especie, data) => ({
  [ESTABLISHMENT.UBICACIONES]: data[MASTERS.PRODUCTIVA_TYPES.UBICACION],
  [ESTABLISHMENT.RODEOS]: data[MASTERS.PRODUCTIVA_TYPES.RODEO],
  [MASTERS.PRODUCTIVA_TYPES.CATEGORIA]: GENERAL.arrayToOptions(
    [
      ...UNITS.CATEGORIES_LIST[especie][UNITS.HEMBRAS],
      ...UNITS.CATEGORIES_LIST[especie][UNITS.MACHOS],
    ],
    (t) => UNITS.CATEGORIES_FORMATTED[t]
  ),
});

const PlanificacionModal = ({
  api,
  visible,
  planificacion = null,
  onSuccess,
  onCancel,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedGestion, setSelectedGestion] = useState(null);
  const [selectedConcepto, setSelectedConcepto] = useState(null);
  const [form] = Form.useForm();
  const { mutate: addPlanificacion, isLoading: isCreating } = useMutation(
    api.addPlanificacion
  );
  const { mutate: editPlanificacion, isLoading: isEditing } = useMutation(
    api.editPlanificacion
  );
  const { mutate: deletePlanificacion, isLoading: isDeleting } = useMutation(
    api.deletePlanificacion
  );
  const initialValues = useMemo(() => {
    // InitialValues when EDIT:
    if (planificacion) {
      setSelectedGestion(planificacion.gestion);
      setSelectedConcepto(planificacion.concepto);
      return {
        ...planificacion,
        objetivo: planificacion[`${planificacion.concepto}_objetivo`],
        dateRange: planificacion.fecha_inicio &&
          planificacion.fecha_fin && [
            moment(planificacion.fecha_inicio),
            moment(planificacion.fecha_fin),
          ],
      };
    }
  }, [planificacion]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, initialValues, visible]);

  const { data: gestionMaster, isLoading: isLoadingGestionOptions } = useQuery(
    [
      'Planificacion::fetchGestiones',
      { masterType: MASTERS.PRODUCTIVA_TYPES.GESTIONES },
    ],
    api.fetchMasters,
    { enabled: visible }
  );

  const { data: eventoMaster, isLoading: isLoadingEventosOptions } = useQuery(
    [
      'Planificacion::fetchEventos',
      { masterType: MASTERS.PRODUCTIVA_TYPES.EVENTOS },
    ],
    api.fetchMasters,
    { enabled: visible }
  );

  const { data: conceptoMaster, isLoading: isLoadingConceptoOptions } =
    useQuery(
      [
        'Planificacion::fetchConceptos',
        { masterType: MASTERS.PRODUCTIVA_TYPES.CONCEPTOS },
      ],
      api.fetchMasters,
      { enabled: visible }
    );

  const { data: objetivoOptions, isLoading: isLoadingObjetivoOptions } =
    useQuery(
      [
        'Planificacion::fetchObjetivos',
        {
          masterTypes: [
            MASTERS.PRODUCTIVA_TYPES.UBICACION,
            MASTERS.PRODUCTIVA_TYPES.RODEO,
          ],
          paramsByType: {
            [MASTERS.PRODUCTIVA_TYPES.UBICACION]: { is_padre: false },
          },
        },
      ],
      api.fetchMastersFormOptions,
      {
        enabled: visible,
        select: (data) => formatPlanificacionObjetivos(api.userSpecies, data),
      }
    );

  const isEventEmpty = !_.isEmpty(planificacion);

  const handleOnCancel = () => {
    onCancel();
  };

  const handleRequestBody = ({ concepto, objetivo, ...restRequestBody }) => {
    return {
      body: {
        ...restRequestBody,
        concepto,
        [`${concepto}_objetivo`]: objetivo,
        fecha_inicio: GENERAL.dateToBack(restRequestBody.dateRange[0]),
        fecha_fin: GENERAL.dateToBack(restRequestBody.dateRange[1]),
      },
    };
  };

  const afterCreatingOrEditing = ({ successMsg = 'agregó' }) => {
    onSuccess();
    message.success(`Se ${successMsg} la planificación con éxito.`);
    handleOnCancel();
  };

  const handleCreate = () => {
    form.validateFields().then((newPlanning) => {
      addPlanificacion(handleRequestBody(newPlanning), {
        onSuccess: afterCreatingOrEditing,
      });
    });
  };

  const handleEdit = () => {
    form
      .validateFields()
      .then((newPlanning) => {
        editPlanificacion(
          { ...handleRequestBody(newPlanning), id: planificacion.id },
          {
            onSuccess: () => afterCreatingOrEditing({ successMsg: 'editó' }),
          }
        );
      })
      .catch(() => {});
  };

  const handleDelete = ({ onSuccess, onError }) => {
    deletePlanificacion({ id: planificacion.id }, { onSuccess, onError });
    setShowDeleteModal(false);
  };

  const renderAddFooter = () => (
    <div className="planificacion-footer">
      <Button onClick={handleOnCancel}>CANCELAR</Button>
      <Button type="primary" onClick={handleCreate} loading={isCreating}>
        AGREGAR
      </Button>
    </div>
  );

  const renderEditFooter = () => (
    <div className="planificacion-footer">
      <Button
        type="primary"
        onClick={() => setShowDeleteModal(true)}
        disabled={isEditing}
      >
        ELIMINAR
      </Button>
      <Space>
        <Button onClick={handleOnCancel}>CANCELAR</Button>
        <Button type="primary" onClick={handleEdit} loading={isEditing}>
          EDITAR
        </Button>
      </Space>
    </div>
  );

  return (
    <>
      <Modal
        open={visible}
        title={
          isEventEmpty ? (
            <ModalTitle icon={<EditOutlined />} title="Editar planificación" />
          ) : (
            <ModalTitle
              icon={<FileAddOutlined />}
              title="Agregar planificación"
            />
          )
        }
        forceRender
        className="planificacion-modal"
        onCancel={handleOnCancel}
        footer={isEventEmpty ? renderEditFooter() : renderAddFooter()}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={initialValues}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Rango de fechas"
                name="dateRange"
                rules={[{ required: true, message: 'Ingrese rango de fechas' }]}
              >
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  format={GENERAL.DATE_FORMAT.FRONT}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Gestión"
                name="gestion"
                rules={[{ required: true, message: 'Ingrese una gestión' }]}
              >
                <Select
                  options={MASTERS.generateOptions(gestionMaster?.results)}
                  placeholder="Seleccionar una gestión"
                  loading={isLoadingGestionOptions}
                  onChange={(v) => {
                    form.setFieldsValue({ evento: undefined });
                    setSelectedGestion(v);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Evento"
                name="evento"
                rules={[{ required: true, message: 'Ingrese una evento' }]}
              >
                <Select
                  disabled={!selectedGestion}
                  options={
                    eventoMaster &&
                    MASTERS.generateOptions(
                      eventoMaster?.results[selectedGestion]
                    )
                  }
                  loading={isLoadingEventosOptions}
                  placeholder="Seleccionar un evento"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Concepto"
                name="concepto"
                rules={[{ required: true, message: 'Ingrese una concepto' }]}
              >
                <Select
                  options={MASTERS.generateOptions(conceptoMaster?.results)}
                  placeholder="Seleccionar un concepto"
                  loading={isLoadingConceptoOptions}
                  onChange={(v) => {
                    form.setFieldsValue({ objetivo: undefined });
                    setSelectedConcepto(v);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Objetivo"
                name="objetivo"
                rules={[{ required: true, message: 'Ingrese una objetivo' }]}
              >
                <Select
                  disabled={!selectedConcepto}
                  options={
                    selectedConcepto &&
                    objetivoOptions &&
                    objetivoOptions[selectedConcepto]
                  }
                  loading={isLoadingObjetivoOptions}
                  placeholder="Seleccionar un objetivo"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <DeleteModal
        title="Eliminar planificación"
        bodyText={
          <span>
            esta <b>planificación</b>
          </span>
        }
        isDeleting={isDeleting}
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        onSuccess={() => {
          onSuccess();
          handleOnCancel();
          setShowDeleteModal(false);
        }}
        onSuccessMessage={'Planificación eliminada con éxito.'}
      />
    </>
  );
};

export default withAPI(PlanificacionModal);
