import React from 'react';
import { Form } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';
import DatoAdicionalInput from './DatoAdicionalInput';

const { Item: FormItem } = Form;

const defaultColumns = (sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'RP Padre',
    dataIndex: 'rp_padre',
    key: 'padre__rp',
    sorter,
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Peso actual',
    dataIndex: 'peso_actual',
    sorter,
    render: (w) => w && `${w} kg`,
  },
];

const datoAdicionalFormColumns = (type = null, selectOptions = {}) => [
  {
    title: 'Valor',
    dataIndex: 'daValue',
    width: '30%',
    render: (value, row) => (
      <FormItem
        initialValue={value}
        name={[`daValue`, `${row.id}`]}
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: 'Ingrese valor' }]}
      >
        <DatoAdicionalInput
          datoAdicionalType={type}
          disabled={!type}
          defaultValue={0}
          selectOptions={selectOptions}
        />
      </FormItem>
    ),
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const DatosAdicionalesTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  actions = null,
  datoAdicionalType = null,
  datosAdicionalesOptions = {},
  ...props
}) => {
  let columns = defaultColumns(true).concat(actionsColumn(actions));
  if (form)
    columns = defaultColumns().concat(
      datoAdicionalFormColumns(datoAdicionalType, datosAdicionalesOptions)
    );
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default DatosAdicionalesTable;
