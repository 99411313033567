import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { Modal, Upload, Button, Progress, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

import './UploadModal.scss';

const UPLOAD_STATUS = {
  STANDBY: 'no-upload',
  UPLOADING: 'uploading',
  ERROR: 'error',
  COMPLETED: 'success',
};

const okButtonText = {
  [UPLOAD_STATUS.STANDBY]: 'CARGAR',
  [UPLOAD_STATUS.UPLOADING]: 'CARGANDO',
  [UPLOAD_STATUS.ERROR]: 'VOLVER A CARGAR',
  [UPLOAD_STATUS.COMPLETED]: 'FINALIZAR',
};

const UploadProgress = ({ completed = false, error = false, text = null }) => {
  const status = error ? 'exception' : completed ? null : 'active';
  const colors = error
    ? null
    : completed
      ? null
      : { '0%': '#054e2d', '100%': '#9ac19b' };
  const defaultText = error
    ? 'Error en la carga'
    : completed
      ? 'Archivo cargado correctamente'
      : 'Cargando archivo...';
  return (
    <>
      <div>{text ?? defaultText}</div>
      <Progress
        percent={100}
        status={status}
        showInfo={completed || error}
        strokeColor={colors}
      />
    </>
  );
};

const UploadDialog = ({ params }) => (
  <Upload className="upload-file-container" {...params}>
    <Button className="upload-file-button">
      <span className="upload-file-text">
        Seleccioná el archivo para realizar la carga
      </span>
      <CloudUploadOutlined className="upload-file-icon" />
    </Button>
  </Upload>
);

const UploadModal = ({
  title = '',
  visible,
  onUpload,
  close,
  apiFn,
  params,
  errorData = {},
  inputFormName = 'input_file',
}) => {
  const [fileList, setFileList] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.STANDBY);
  const [errorText, setErrorText] = useState(null);
  const { mutate: uploadFile, isLoading: uploading } = useMutation(apiFn);

  // NOTE: This is for Sync Screen, the file is processed by ID and return error if something has to be changed.
  useEffect(() => {
    if (visible && !_.isEmpty(errorData)) {
      setUploadStatus(UPLOAD_STATUS.ERROR);
      setErrorText(
        <div>
          <div>{errorData.message}</div>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => saveAs(errorData.file, `${title}-Error.xlsx`)}
          >
            Descargar registro de errores.
          </Button>
        </div>
      );
    }
  }, [visible, errorData, title]);

  const onResetUploadModal = () => {
    setFileList([]);
    setUploadStatus(UPLOAD_STATUS.STANDBY);
  };
  const onCloseUploadModal = () => {
    onResetUploadModal();
    close();
  };

  const onUploadFile = () => {
    const onSuccess = (data) => {
      onUpload();
      if (data.error) {
        setUploadStatus(UPLOAD_STATUS.ERROR);
        setErrorText(
          <div>
            <div>{data.message}</div>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => saveAs(data.file, `${title}-Error.xlsx`)}
            >
              Descargar registro de errores.
            </Button>
          </div>
        );
      } else {
        setUploadStatus(UPLOAD_STATUS.COMPLETED);
        onCloseUploadModal();
        message.success('Archivo cargado con éxito.');
      }
    };
    const onError = ({ message, description }) => {
      if (message === '400') setErrorText(description);
      if (message === '403')
        setErrorText(
          'Ha alcanzado la cantidad máxima de Madres que permite su licencia'
        );
      if (message === '500')
        setErrorText('Hubo un error al conectarse al servidor');
      setUploadStatus(UPLOAD_STATUS.ERROR);
    };
    const fileAsForm = new FormData();
    fileAsForm.append(inputFormName, fileList[0]);
    setUploadStatus(UPLOAD_STATUS.UPLOADING);
    uploadFile({ ...params, inputFile: fileAsForm }, { onSuccess, onError });
  };

  const uploadParams = {
    name: 'file',
    accept: '.xls, .xlsx',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: (f) => {
      setFileList([f]);
      return false;
    },
    onRemove: () => setFileList([]),
    fileList: fileList,
  };

  const renderUpload = () => {
    switch (uploadStatus) {
      case UPLOAD_STATUS.STANDBY:
        return <UploadDialog params={uploadParams} />;
      case UPLOAD_STATUS.UPLOADING:
        return <UploadProgress />;
      case UPLOAD_STATUS.ERROR:
        return <UploadProgress error text={errorText} />;
      case UPLOAD_STATUS.COMPLETED:
        return <UploadProgress completed />;
      default:
        return null;
    }
  };

  const onOkFn = () => {
    if (uploadStatus === UPLOAD_STATUS.STANDBY) return onUploadFile;
    if (uploadStatus === UPLOAD_STATUS.ERROR) return onResetUploadModal;
    return onCloseUploadModal;
  };

  return (
    <Modal
      title={
        <>
          <CloudUploadOutlined />
          {'Cargar Excel'}
        </>
      }
      className="upload-file-modal"
      cancelText="CANCELAR"
      okText={okButtonText[uploadStatus]}
      okButtonProps={{
        disabled: fileList.length === 0 && _.isEmpty(errorData),
      }}
      confirmLoading={uploading}
      open={visible}
      onCancel={onCloseUploadModal}
      onOk={onOkFn()}
    >
      <div className="upload-file-modal-body">{renderUpload()}</div>
    </Modal>
  );
};

export default UploadModal;
