import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { Modal, Form, message, Col, Row, DatePicker, InputNumber } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { ModalTitle } from 'modules/core/components';
import { GENERAL } from 'modules/core/constants';

const AdquisicionesModalAdd = ({
  api,
  visible,
  inputType,
  onSuccess,
  onCancel,
  afterClose,
  customAddForm = {},
}) => {
  const CUSTOM_FORM = customAddForm;
  const [form] = Form.useForm();
  const { mutate: addAdquisicion, isLoading: isAdding } = useMutation(
    api.addAdquisicion
  );

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const handleOnCancel = () => {
    clearForm();
    onCancel();
  };

  const formatCustomForm = (fields = {}, values = {}) =>
    fields.reduce((accum, field) => ({ ...accum, [field]: values[field] }), {});

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const addedAdquisicion = {
          fecha: GENERAL.dateToBack(values.fecha),
          cantidad: values.cantidad,
          ...(CUSTOM_FORM?.outerFields?.length > 0 &&
            formatCustomForm(CUSTOM_FORM.outerFields, values)),
          [inputType]: formatCustomForm(CUSTOM_FORM.fields, values),
        };
        const handleOnSuccess = () => {
          onSuccess();
          message.success('Se agrego la adquisición con éxito.');
          handleOnCancel();
        };
        const handleOnError = ({ message: error, description }) => {
          if (error === '400') message.error(description, 5);
        };

        addAdquisicion(
          { body: addedAdquisicion, inputType },
          { onSuccess: handleOnSuccess, onError: handleOnError }
        );
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        <ModalTitle icon={<FileAddOutlined />} title="Agregar adquisición" />
      }
      forceRender
      cancelText="CANCELAR"
      okText="AGREGAR"
      confirmLoading={isAdding}
      open={visible}
      onCancel={handleOnCancel}
      onOk={onConfirm}
      afterClose={afterClose}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha de adquisición"
              name="fecha"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Cantidad"
              name="cantidad"
              rules={[{ required: true, message: 'Ingrese cantidad' }]}
            >
              <InputNumber
                min={1}
                style={{ width: '100%' }}
                placeholder="Indicar cantidad"
              />
            </Form.Item>
          </Col>
          {CUSTOM_FORM?.render({ visible })}
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(AdquisicionesModalAdd);
