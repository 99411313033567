import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Modal, message as antMessage, InputNumber, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { ModalTitle } from 'modules/core/components';
import './InsumosModalDecrement.scss';

const InsumosModalDecrement = ({
  api,
  id,
  inputType,
  visible,
  onCancel,
  onSuccess,
}) => {
  const [decrementAmount, setDecrementAmount] = useState(1);
  const { mutate: decrementInput, isLoading: isDecrementing } = useMutation(
    api.decrementInsumo
  );
  const onDecrementInput = () => {
    const handleOnSuccess = () => {
      onSuccess();
      antMessage.success('El insumo se decrementó con éxito.');
    };
    const handleOnError = ({ description }) => {
      antMessage.error(description);
    };
    decrementInput(
      {
        id,
        inputType,
        amount: decrementAmount,
      },
      { onSuccess: handleOnSuccess, onError: handleOnError }
    );
  };

  return (
    <Modal
      title={<ModalTitle icon={<CaretDownOutlined />} title="Decrementar" />}
      className="finca-decrement-modal"
      cancelText="CANCELAR"
      okText="DECREMENTAR"
      confirmLoading={isDecrementing}
      open={visible}
      onCancel={onCancel}
      onOk={onDecrementInput}
    >
      <div className="finca-decrement-modal-body">
        ¿Está seguro que desea decrementar este insumo?
        <Space>
          Ingrese cantidad:
          <InputNumber
            min={1}
            defaultValue={decrementAmount}
            onChange={setDecrementAmount}
          />
        </Space>
      </div>
    </Modal>
  );
};

export default withAPI(InsumosModalDecrement);
