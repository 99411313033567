import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal, message as antMessage } from 'antd';
import ModalTitle from '../ModalTitle/ModalTitle';

import './DeleteModal.scss';

const DeleteModal = ({
  title = 'Eliminar',
  bodyText = '',
  okText = 'ELIMINAR',
  visible,
  isDeleting = false,
  onCancel,
  onConfirm,
  onSuccess,
  onSuccessMessage = 'Eliminación exitosa.',
  children,
}) => {
  const handleOnDelete = () => {
    const handleOnSuccess = () => {
      onSuccess();
      antMessage.success(onSuccessMessage);
    };
    const handleOnError = ({ description } = {}) => {
      antMessage.error(description || 'Error', 5);
    };

    onConfirm({ onSuccess: handleOnSuccess, onError: handleOnError });
  };

  return (
    <Modal
      className="finca-delete-modal"
      title={<ModalTitle icon={<DeleteOutlined />} title={title} />}
      open={visible}
      okText={okText}
      cancelText="CANCELAR"
      onOk={handleOnDelete}
      onCancel={onCancel}
      confirmLoading={isDeleting}
    >
      <div className="finca-delete-modal-body">
        {children ? (
          children
        ) : (
          <span>¿Está seguro que desea eliminar {bodyText}?</span>
        )}
      </div>
    </Modal>
  );
};

export default DeleteModal;
