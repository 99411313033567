import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import { withAPI } from 'modules/api';
import { UNITS } from 'modules/core/constants';
import { Button, message } from 'antd';
import {
  EllipsisOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FileSyncOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  UploadModal,
  ExportFieldsModal,
  DropdownMenu,
} from 'modules/core/components';

const UPLOAD_STATE = {
  NEW: { view: true, update: false },
  UPDATE: { view: true, update: true },
  NONE: { view: false, update: false },
};

const UnitsMenu = ({ api, exportParams, onUpload, onDeleteMultiple }) => {
  const [viewExport, setViewExport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [viewUpload, setViewUpload] = useState(UPLOAD_STATE.NONE);
  const { mutate: exportUnits } = useMutation(api.exportUnits);
  const { mutate: downloadTemplate } = useMutation(api.downloadUnitsTemplate);

  const { data: availableFields } = useQuery(
    ['Units::exportAvailableFields', { unitType: UNITS.UNIDAD }],
    api.fieldsExportUnits,
    { enabled: viewExport }
  );

  const onExportUnits = ({ _nogroup: fields }) => {
    setIsExporting(true);
    exportUnits(
      { fields, params: exportParams },
      {
        onSuccess: (response) => {
          saveAs(response, `Unidades-Exportados.xlsx`);
          setIsExporting(false);
          setViewExport(false);
        },
        onError: ({ message: error }) => {
          if (error === '500') {
            message.error('Hubo un error al conectarse al servidor.');
          }
          setIsExporting(false);
        },
      }
    );
  };

  const onDownloadTemplate = (params = {}) => {
    downloadTemplate(
      { params },
      { onSuccess: (response) => saveAs(response, `Unidades-Plantilla.xlsx`) }
    );
  };

  return (
    <>
      <DropdownMenu
        menu={[
          {
            title: 'Plantilla para alta',
            icon: <CloudDownloadOutlined />,
            onClick: () => onDownloadTemplate(),
          },
          {
            title: 'Alta masiva',
            icon: <FileAddOutlined />,
            onClick: () => setViewUpload(UPLOAD_STATE.NEW),
          },
          {
            title: 'Plantilla para modificación',
            icon: <CloudDownloadOutlined />,
            onClick: () =>
              onDownloadTemplate({ update: true, ...exportParams }),
          },
          {
            title: 'Modificación masiva',
            icon: <FileSyncOutlined />,
            onClick: () => setViewUpload(UPLOAD_STATE.UPDATE),
          },
          {
            title: 'Exportar a Excel',
            icon: <DownloadOutlined />,
            onClick: () => setViewExport(true),
          },
          {
            title: 'Eliminar masivo',
            icon: <DeleteOutlined />,
            onClick: () => onDeleteMultiple(),
          },
        ]}
      >
        <Button icon={<EllipsisOutlined />} />
      </DropdownMenu>
      <UploadModal
        title="Unidades"
        apiFn={viewUpload.update ? api.uploadUpdatedUnits : api.uploadUnits}
        visible={viewUpload.view}
        onUpload={onUpload}
        close={() => setViewUpload(UPLOAD_STATE.NONE)}
      />
      <ExportFieldsModal
        visible={viewExport}
        fields={availableFields}
        loading={isExporting}
        onSuccess={onExportUnits}
        close={() => setViewExport(false)}
      />
    </>
  );
};

export default withAPI(UnitsMenu);
